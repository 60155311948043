import React from "react";
import { Dropdown, Flex } from "@fluentui/react-northstar";
import { AVATAR_DEFAULT } from "../../../../constants";
import styled from "styled-components";

const SearchBarEmployeeList = ({ users = [], onChangeUser = () => null }) => {
    return (
        <Flex fill>
            <DropdownStyle
                fluid
                search
                items={users?.map((user) => {
                    return {
                        id: user?.id,
                        header: user?.name,
                        image: user?.avatar || AVATAR_DEFAULT,
                    };
                })}
                placeholder="Rechercher..."
                noResultsMessage="Nous n'avons pas trouvé de correspondance."
                a11ySelectedItemsMessage="Appuyez sur la touche Delete ou Backspace pour supprimer"
                clearable
                onChange={(e, i) => {
                    const id_user_selected = i?.value?.id;
                    onChangeUser(id_user_selected);
                    return;
                }}
            />
        </Flex>
    );
};

export default SearchBarEmployeeList;

const DropdownStyle = styled(Dropdown)`
    min-width: 100%;
`;
