import { Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import celebrationEmojis from "../emojis/Solid.svg";
import Sad from "../emojis/Sad.svg";
import Heart from "../emojis/heart.svg";
import Like from "../emojis/like.svg";
import { TeamCard } from "./TeamCard";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import CardUser from "../../../features/employee_folder/components/Cards/CardPositionEntreprise/CardUser";
import { DEVICE } from "../../../constants/responsive";

const SocietyActivity = ({ company_activities, title }) => {
    const birthday_users = company_activities?.birthdays;
    const leave_users = company_activities?.leaves;
    const new_arrival_users = company_activities?.new_arrivals;
    const work_birthday_users = company_activities?.work_birthdays;

    const birthday_users_empty = birthday_users?.length;
    const leave_users_empty = birthday_users?.length;
    const new_arrival_users_empty = new_arrival_users?.length;
    const work_birthday_users_empty = work_birthday_users?.length;

    const all_activity_is_empty =
        !birthday_users_empty &&
        !leave_users_empty &&
        !new_arrival_users_empty &&
        !work_birthday_users_empty;

    return (
        <Flex column gap="gap.medium">
            <DocumentTitle content={title} />

            {all_activity_is_empty ? (
                <ActivityContent hAlign="center">
                    <EmptyDataText content="Aucune activité aujourd'hui" />
                </ActivityContent>
            ) : (
                <ActivityContent column gap="gap.large">
                    <Flex column>
                        {birthday_users?.length > 0 && (
                            <ActivityCard
                                activity_title={"Anniversaires du jour"}
                                description={"Une bougie de plus !"}
                                emojis={celebrationEmojis}
                                badge={birthday_users?.length}
                                users={birthday_users}
                            />
                        )}
                        {leave_users?.length > 0 && (
                            <ActivityCard
                                activity_title={"Absences"}
                                description={
                                    "Impossible de les croiser aujourd’hui !"
                                }
                                emojis={Sad}
                                badge={leave_users?.length}
                                users={leave_users}
                            />
                        )}
                        {new_arrival_users?.length > 0 && (
                            <ActivityCard
                                activity_title={"Nouvelles arrivées"}
                                description={"Ils démarrent ce mois-ci !"}
                                emojis={Heart}
                                badge={new_arrival_users?.length}
                                users={new_arrival_users}
                            />
                        )}
                        {work_birthday_users?.length > 0 && (
                            <ActivityCard
                                activity_title={"Anniversaires pro du mois"}
                                description={"L’ancienneté va faire un +1!"}
                                emojis={Like}
                                badge={work_birthday_users?.length}
                                users={work_birthday_users}
                            />
                        )}
                    </Flex>
                </ActivityContent>
            )}
        </Flex>
    );
};

export default SocietyActivity;

const EmptyDataText = styled(Text)`
    color: #616161;
`;

const ActivityCard = ({
    activity_title,
    description,
    emojis,
    badge,
    users,
}) => {
    // * navigate to any employee
    const navigate = useNavigate();
    const _navigateToEmployee = (id) => {
        if (isNaN(id)) {
            return console.log("is not a valid ID employee");
        }

        return navigate(ROUTES.employee_folder.custom_route(id));
    };
    return (
        <Flex column gap="gap.medium">
            <Flex gap="gap.medium" vAlign="center">
                <EmojiStyle>
                    <Image src={emojis} />
                </EmojiStyle>
                <Flex column gap="gap.smaller">
                    <Flex vAlign="center" gap="gap.small">
                        <ActivityTitle content={activity_title} />

                        {/* <CustomBadge
                            size="smaller"
                            label="1"
                            bg="var(--bg-orange)"
                            appearence="filled"
                            color="black"
                            rectangular={false}
                            isMobileS={false}
                        /> */}

                        <CustomBadge vAlign="center" hAlign="center">
                            <Text
                                styles={{ fontWeight: "bold" }}
                                content={badge}
                                s
                                color="white"
                            />
                        </CustomBadge>
                    </Flex>
                    <Text content={description} />
                </Flex>
            </Flex>
            <Flex
                column
                styles={{
                    marginLeft: "55px",
                    maxHeight: "295px",
                    overflowY: "scroll",
                }}
            >
                {users?.map((el) => {
                    return (
                        <CardUser
                            key={el?.id}
                            avatar={el?.avatar}
                            full_name={`${el?.first_name} ${el?.last_name}`}
                            job_title={el?.job_title}
                            hideTitle={false}
                            onClick={() => _navigateToEmployee(el?.id)}
                        />
                    );
                })}
            </Flex>
        </Flex>
    );
};

const DocumentTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #242424;
`;

const ActivityContent = styled(Flex)`
    padding: 24px 16px;
    gap: 16px;
    background: #ffffff;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    @media ${DEVICE.laptop} {
        width: 445px;
    }
    @media ${DEVICE.mobileS} {
        width: 100%;
    }
`;

const ActivityTitle = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #4f52b2;
`;
const EmojiStyle = styled(Flex)`
    border-radius: 22px;
    padding: 8px;
    background-color: #f5f5f5;
    width: 40px;
    height: 40px;
`;

const CustomBadge = styled(Flex)`
    /* margin-inline: 10px; */
    /* padding: 3px 5px; */
    gap: 10px;
    width: 20px;
    height: 20px;
    /* border: 3px solid rgb(255, 170, 68); */
    border-radius: 16px;
    background-color: rgb(255, 170, 68);
    justify-content: center;
    font-size: small;
    align-items: center;
`;
