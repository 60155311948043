import React from "react";
import { ContainerFluid } from "../../../../common/styles";
import { Avatar, Button, Flex, Loader, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { useQuery } from "react-query";
import { get_team_balance } from "../../api";
import EmptyDemande from "../EmptyDemande";
import moment from "moment";
import { DEVICE } from "../../../../constants/responsive";

const ManagerSoldeDetail = ({ setShowSoldeDetail }) => {
    const {
        data: teamBalance,
        isLoading: loadingteamBalance,
        isFetching: fetchingTeamBalance,
    } = useQuery(["manager_solde_data"], () => get_team_balance(), {
        refetchOnWindowFocus: false,
    });

    if (loadingteamBalance || fetchingTeamBalance)
        return <Loader label="Chargement des données ..." />;

    const all_users = teamBalance?.all_users;
    const leave_types = teamBalance?.leave_types;
    const totalBalances = teamBalance?.total;

    return (
        <ContainerFluid column fill>
            {/* body of the table  */}
            <Container column styles={{ overflowX: "scroll" }}>
                {/* header text */}
                <HeaderAction space="between" vAlign="center" fill>
                    <HeaderFlex vAlign="center">
                        <TextHeader content="Vue d'ensemble des soldes de mon équipe" />
                    </HeaderFlex>
                    <ButtonStyle
                        onClick={() => setShowSoldeDetail(false)}
                        primary
                        text
                        content="Retour"
                    />
                </HeaderAction>
                <>
                    {all_users?.length ? (
                        <Flex column>
                            <Flex
                                styles={{
                                    height: "400px",
                                    overflowY: "auto",
                                    paddingBottom: "45px",
                                }}
                            >
                                {/* all users */}
                                <Flex
                                    column
                                    styles={{
                                        marginRight: "40px",
                                        marginTop: "88px",
                                    }}
                                >
                                    {all_users?.map((el) => {
                                        return (
                                            <Column Align="center">
                                                <FlexAvatarName vAlign="center">
                                                    <Avatar
                                                        styles={{
                                                            width: "40px",
                                                            height: "40px",
                                                        }}
                                                        name={el?.name}
                                                        image={el?.avatar}
                                                    />
                                                    <NameText
                                                        truncated
                                                        content={el?.name}
                                                    />
                                                </FlexAvatarName>
                                            </Column>
                                        );
                                    })}
                                </Flex>
                                {/* leave types */}
                                <Flex
                                    styles={{
                                        gap: "40px",
                                    }}
                                >
                                    {leave_types?.map((el) => {
                                        return (
                                            <Flex
                                                column
                                                styles={{
                                                    border: `1px solid ${el?.color}`,
                                                    height: "fit-content",
                                                    borderRadius:
                                                        "9px 9px 0px 0px",
                                                    paddingBottom: "25px",
                                                }}
                                            >
                                                <TableHead
                                                    background={el?.color}
                                                    border={el?.color}
                                                    name={el?.name}
                                                />
                                                {el?.type === "recuperation" ||
                                                !el?.end_period ? (
                                                    <Flex
                                                        hAlign="center"
                                                        styles={{
                                                            padding:
                                                                "8px 16px 16px 16px",
                                                        }}
                                                    >
                                                        <RecuperationText content="Actuel" />
                                                    </Flex>
                                                ) : (
                                                    <Flex
                                                        space="between"
                                                        styles={{
                                                            padding:
                                                                "8px 16px 16px 16px",
                                                        }}
                                                    >
                                                        <Actuellement content="Actuel" />
                                                        <DateStyle
                                                            content={`Au ${moment(
                                                                el?.end_period
                                                            ).format(
                                                                "DD/MM/YY"
                                                            )}`}
                                                        />
                                                    </Flex>
                                                )}

                                                <Flex
                                                    column
                                                    style={{
                                                        gap: "14px",
                                                    }}
                                                >
                                                    {all_users?.map((user) => {
                                                        const balanceInfo =
                                                            user?.balances.find(
                                                                (balance) =>
                                                                    balance?.type ===
                                                                    el?.type
                                                            );
                                                        return (
                                                            <Flex
                                                                gap="gap.medium"
                                                                fill
                                                                space="between"
                                                                style={{
                                                                    height: "40px",
                                                                    paddingInline:
                                                                        "16px",
                                                                }}
                                                            >
                                                                {/* current balance  */}
                                                                <Flex
                                                                    vAlign="center"
                                                                    hAlign="center"
                                                                    fill
                                                                >
                                                                    <CurrentBalanceText
                                                                        content={
                                                                            balanceInfo
                                                                                ? balanceInfo.current_balance.toFixed(
                                                                                      2
                                                                                  )
                                                                                : "--"
                                                                        }
                                                                    />
                                                                </Flex>
                                                                {balanceInfo?.futur_balance ? (
                                                                    <FutureBalanceFlex
                                                                        vAlign="center"
                                                                        hAlign="center"
                                                                        fill
                                                                    >
                                                                        <FutureBalanceText
                                                                            content={
                                                                                balanceInfo?.futur_balance
                                                                            }
                                                                        />
                                                                    </FutureBalanceFlex>
                                                                ) : null}
                                                            </Flex>
                                                        );
                                                    })}
                                                </Flex>
                                            </Flex>
                                        );
                                    })}
                                </Flex>
                            </Flex>
                            {/* total équipe  */}
                            <Flex
                                styles={{
                                    gap: "38px",
                                    border: "1px solid #edebeb",
                                    width: "fit-content",
                                    padding: "10px 10px",
                                    background: "white",
                                    boxShadow:
                                        "5px 5px 10px rgba(0, 0, 0, 0.07)",
                                }}
                            >
                                <Flex
                                    vAlign="center"
                                    styles={{
                                        width: "192px",
                                    }}
                                >
                                    <TotalText content="Total équipe" />
                                </Flex>
                                <Flex
                                    styles={{
                                        gap: "38px",
                                    }}
                                >
                                    {totalBalances?.map((balance) => (
                                        <Flex
                                            gap="gap.medium"
                                            vAlign="center"
                                            hAlign="center"
                                            styles={{
                                                width: "202px",
                                                height: "40px",

                                                paddingInline: "16px",
                                            }}
                                            key={balance?.type}
                                        >
                                            <Flex
                                                vAlign="center"
                                                hAlign="center"
                                                fill
                                            >
                                                <CurrentBalanceText
                                                    content={`${balance?.current_balance} j`}
                                                />
                                            </Flex>
                                            {balance?.futur_balance ? (
                                                <FutureBalanceFlex
                                                    vAlign="center"
                                                    hAlign="center"
                                                    fill
                                                >
                                                    <TotalFutureBalanceText
                                                        content={`${balance?.futur_balance} j`}
                                                    />
                                                </FutureBalanceFlex>
                                            ) : null}
                                        </Flex>
                                    ))}
                                </Flex>
                            </Flex>
                        </Flex>
                    ) : (
                        <Flex column gap="gap.large" hAlign="center">
                            <EmptyDemande forLeave={true} forTeamSolde={true} />
                            <EmptyUserText content="Aucune personne dans votre équipe pour le moment" />
                        </Flex>
                    )}
                </>
            </Container>
        </ContainerFluid>
    );
};

export default ManagerSoldeDetail;

const FlexAvatarName = styled(Flex)`
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid white;
    padding-inline: 10px;
    gap: 12px;
`;

const TableHead = ({ background, name, border }) => {
    return (
        <Header
            hAlign="center"
            styles={{
                // border: `1px solid ${background}  `,
                borderRadius: "4px 4px 0px 0px",
            }}
        >
            {/* header */}
            <HeaderText
                styles={{
                    background: background,
                    // border: `1px solid ${border}`,
                    borderRadius: "4px 4px 0px 0px",
                }}
                content={name}
            />
        </Header>
    );
};

const ButtonStyle = styled(Button)`
    @media ${DEVICE.mobileS} {
        min-width: fit-content;
    }
    @media ${DEVICE.laptop} {
        min-width: 96px;
    }
`;

const HeaderAction = styled(Flex)`
    margin-bottom: 30px;
    padding-top: 15px;
    width: 100%;
`;

const Header = styled(Flex)`
    width: 199px;
    border-radius: 8px 8px 0px 0px;
`;

const Container = styled(Flex)`
    max-width: 1440px;
    margin: 24px auto;
    width: 100%;
    background: white;
    @media ${DEVICE.mobileS} {
        padding: 0px 20px 20px 20px;
    }
    @media ${DEVICE.laptop} {
        padding: 0px 40px 20px 40px;
    }
`;

const HeaderText = styled(Text)`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 100%;
    /* height: 40px; */
    padding-block: 10px;
    text-align: center;
    border-radius: 8px 8px 0px 0px;
`;

const HeaderFlex = styled(Flex)`
    gap: 80px;
`;

const TextHeader = styled(Text)`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;
const DateTextStyle = styled(Text)`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: #5b5fc7;
`;

const BoxStyle = styled(Flex)`
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 4px;
    background: #e8ebfa;
`;

const Column = styled(Flex)`
    width: 202px;
    padding-bottom: 12px;
    /* margin-top: 88px; */
    /* height: 200px; */
`;

const RecuperationText = styled(Text)`
    color: #616161;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;
const Actuellement = styled(Text)`
    color: #616161;
    margin-left: 15px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const DateStyle = styled(Text)`
    color: #5b5fc7;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const NameText = styled(Text)`
    color: #424242;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

const CurrentBalanceText = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
const FutureBalanceText = styled(Text)`
    color: #5b5fc7;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
const TotalFutureBalanceText = styled(Text)`
    color: black;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
const FutureBalanceFlex = styled(Flex)`
    width: 82px;
    border-radius: 4px;
    background: #e8ebfa;
    flex-shrink: 0;
`;

const TotalText = styled(Text)`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const EmptyUserText = styled(Text)`
    color: #616161;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;
