import * as yup from "yup";

/**
 * this SCHEMA validate first step of general informations.
 */
export const SCHEMA = yup
    .object({
        name: yup
            .string()
            .min(2, "pages.add_employee.step_info.form.errors.name")
            .required("pages.add_employee.step_info.form.errors.name"),
        last_name: yup
            .string()
            .min(2, "pages.add_employee.step_info.form.errors.name")
            .required("pages.add_employee.step_info.form.errors.name"),
        used_name: yup
            .string()
            .min(2, "pages.add_employee.step_info.form.errors.name")
            .required("pages.add_employee.step_info.form.errors.name"),
        email: yup
            .string()
            .email("pages.add_employee.step_info.form.errors.email")
            .required(),
        telephone: yup
            .string()
            .min(12, "pages.add_employee.step_info.form.errors.telephone")
            .required(),
        poste: yup.string().required(),
        type_contrat: yup
            .string()
            .min(3, "pages.add_employee.step_info.form.errors.type_contrat.min")
            .required(
                "pages.add_employee.step_info.form.errors.type_contrat.required"
            ),
        managers: yup
            .string()
            .email("pages.add_employee.step_info.form.errors.manager.email")
            .required(
                "pages.add_employee.step_info.form.errors.manager.required"
            ),
        services: yup
            .string()
            .min(3, "pages.add_employee.step_info.form.errors.service.min")
            .required(
                "pages.add_employee.step_info.form.errors.service.required"
            ),
        agencies: yup
            .string()
            .min(3, "pages.add_employee.step_info.form.errors.agencies.min")
            .required(
                "pages.add_employee.step_info.form.errors.agencies.required"
            ),
        date_arrivee: yup
            .date("pages.add_employee.step_info.form.errors.date_arrivee.date")
            .typeError(
                "pages.add_employee.step_info.form.errors.date_arrivee.typeError"
            )
            .required(
                "pages.add_employee.step_info.form.errors.date_arrivee.required"
            ),
        date_depart: yup
            .date("pages.add_employee.step_info.form.errors.date_depart.date")
            .nullable("null")
            .notRequired("not required")
            .transform((curr, org) => (org !== "" ? curr : null))
            .min(yup.ref("date_arrivee"), "end date can't be before start date")
            .min(
                yup.ref("trial_period"),
                "end date can't be before trial period date"
            )
            .typeError(
                "pages.add_employee.step_info.form.errors.date_depart.typeError"
            ),
        trial_period: yup
            .date("pages.add_employee.step_info.form.errors.date_period.date")
            .nullable("it's null")
            .transform((curr, org) => (org !== "" ? curr : null))
            .min(
                yup.ref("date_arrivee"),
                "trial date can't be before start date"
            )
            .typeError(
                "pages.add_employee.step_info.form.errors.date_period.typeError"
            )
            .required(
                "pages.add_employee.step_info.form.errors.date_period.required"
            ),
    })
    .required();

/**
 * this is used for validating task on Dialog modal.add task
 */
export const DIALOG_ADD_TASK_RULES = {
    title: {
        required: {
            value: true,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.title.required.message",
        },
        minLength: {
            value: 2,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.title.minLength.message",
        },
    },
    category: {
        required: {
            value: true,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.category.required.message",
        },
    },
    description: {
        required: {
            value: true,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.description.required.message",
        },
        // minLength: {
        // 	value: 20,
        // 	message:
        // 		"pages.add_employee.step_tasks.dialog_add_task_rules.description.minLength.message",
        // },
    },
    due_date: {
        required: {
            value: true,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.due_date.required.message",
        },
        min: {
            value: new Date().toJSON().split("T")[0],
            message: "you cant choose a date in the past",
        },
    },
    assigned_to: {
        required: {
            value: true,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.assigned_to.required.message",
        },
    },
    related_to: {
        required: {
            value: true,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.related_to.required.message",
        },
    },
    who_validate: {
        required: {
            value: true,
            message:
                "pages.add_employee.step_tasks.dialog_add_task_rules.who_validate.required.message",
        },
    },
};
