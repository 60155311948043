import React, { useCallback, useMemo } from "react";
import {
    AddIcon,
    Checkbox,
    Datepicker,
    Dropdown,
    Flex,
    InputLabel,
    Loader,
} from "@fluentui/react-northstar";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
import {
    DatesFields,
    DropdownEmployees,
    DropdownTypeSolde,
    EmployeesDropdown,
    SubTitle,
    WrapperCheckboxes,
} from "./index.style";
import PillListEmployeeSelected from "./PillListEmployeeSelected";
import PillListTypeSelected from "./PillListTypeSelected";
import { TYPES_OPERATION_AJUSTMENT, TYPES_STATUS_LEAVE } from "./constants";
import {
    _getAllUsers,
    _get_all_employees_and_departments,
} from "../../../../../api/user";
import { _get_adjustement_user, _get_types_leave } from "../../../api";
import {
    AVATAR_DEFAULT,
    AVATAR_DEPARTMENT_DEFAULT,
} from "../../../../../constants";
import { ErrorMessage } from "../../../../../common/styles";

const ContentFilterAjustment = ({
    employees,
    is_loading_employees,
    leave_types,
    isManager,
    isHR,
    filterData,
    exportData,
}) => {
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const watch_employees = watch("employee"); // que des IDS
    const watch_start_date = watch("start_date");
    const watch_end_date = watch("end_date");

    const watch_checkboxes = watch("checkboxes");
    const watch_checkboxes_status = watch("checkboxes_status");
    const watch_type = watch("type"); // que des IDS

    const selectAllOrDeselectAll = (e, { checked }) => {
        if (checked) {
            setValue(
                "checkboxes",
                TYPES_OPERATION_AJUSTMENT.map((e) => e?.id)
            );
            return;
        } else {
            setValue("checkboxes", []);
            return;
        }
    };
    const selectAllOrDeselectAllStatus = (e, { checked }) => {
        if (checked) {
            setValue(
                "checkboxes_status",
                TYPES_STATUS_LEAVE.map((e) => e?.id)
            );
            return;
        } else {
            setValue("checkboxes_status", []);
            return;
        }
    };
    const addOrRemoveChecked = (id, checked) => {
        if (!checked) {
            // uncheck it
            const new_state = watch_checkboxes.filter((cb_id) => cb_id !== id);
            setValue("checkboxes", new_state);
        } else {
            // check it
            const new_array = [...watch_checkboxes, id];
            setValue("checkboxes", new_array);
        }
        return;
    };
    const addOrRemoveCheckedStatus = (id, checked) => {
        if (!checked) {
            // uncheck it
            const new_state = watch_checkboxes_status.filter(
                (cb_id) => cb_id !== id
            );
            setValue("checkboxes_status", new_state);
        } else {
            // check it
            const new_array = [...watch_checkboxes_status, id];
            setValue("checkboxes_status", new_array);
        }
        return;
    };
    const format_employees = () => {
        return employees
            ?.map((empl) => ({
                id: empl?.id,
                header: empl?.name,
                image: empl?.is_department
                    ? AVATAR_DEPARTMENT_DEFAULT
                    : empl?.avatar
                    ? empl?.avatar
                    : AVATAR_DEFAULT,
                content: empl.job_title,
            }))
            .sort((a, b) => b.id.localeCompare(a.id));
    };

    const formated_employees = format_employees();

    const format_type = useMemo(() => {
        return leave_types?.map((lt) => ({
            id: lt?.id,
            header: lt?.name,
            color: lt?.color,
        }));
    }, [leave_types]);

    // console.log({ watch_employees, watch_checkboxes, watch_type });
    // console.log({ watch_checkboxes_status });

    return (
        <Flex column gap="gap.large">
            <Flex column gap="gap.medium">
                <Flex>
                    {filterData && (
                        <SubTitle content={"Période de la recherche"} />
                    )}
                    {exportData && <SubTitle content={"Période de l'export"} />}
                </Flex>
                <DatesFields>
                    <Flex column>
                        <InputLabel content={"À partir du"} />
                        <Controller
                            name="start_date"
                            control={control}
                            rules={{
                                required: "Date de début est obligatoire",
                            }}
                            render={({
                                field: { onChange, value, ref, name },
                            }) => {
                                return (
                                    <Datepicker
                                        defaultSelectedDate={watch_start_date}
                                        formatMonthDayYear={(date) => {
                                            return moment(date).format(
                                                "DD/MM/YYYY"
                                            );
                                        }}
                                        inputPlaceholder="Date de début"
                                        input={{
                                            clearable: true,
                                            error: errors?.start_date?.message,
                                            value: value
                                                ? moment(value).format(
                                                      "DD/MM/YYYY"
                                                  )
                                                : "",
                                        }}
                                        onDateChange={(e, i) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onChange(i?.value);
                                        }}
                                        ref={ref}
                                        name={name}
                                    />
                                );
                            }}
                        />
                        {errors?.start_date && (
                            <ErrorMessage
                                content={errors?.start_date?.message}
                            />
                        )}
                    </Flex>
                    <Flex column>
                        <InputLabel content={"Jusqu'au"} />
                        <Controller
                            name="end_date"
                            control={control}
                            rules={{
                                required: "Date de fin est obligatoire",
                            }}
                            render={({
                                field: { onChange, name, ref, value },
                            }) => {
                                return (
                                    <Datepicker
                                        defaultSelectedDate={watch_end_date}
                                        inputPlaceholder="Date de fin"
                                        formatMonthDayYear={(date) => {
                                            return moment(date).format(
                                                "DD/MM/YYYY"
                                            );
                                        }}
                                        input={{
                                            clearable: true,
                                            error: errors?.end_date?.message,
                                            // value: value
                                            //     ? moment(value).format(
                                            //           "DD/MM/YYYY"
                                            //       )
                                            //     : "",
                                        }}
                                        onDateChange={(e, i) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onChange(i?.value);
                                        }}
                                        name={name}
                                        ref={ref}
                                    />
                                );
                            }}
                        />
                        {errors?.end_date && (
                            <ErrorMessage content={errors?.end_date?.message} />
                        )}
                    </Flex>
                </DatesFields>
            </Flex>
            <Flex column gap="gap.medium">
                <Flex>
                    {filterData && (
                        <SubTitle content={"Critères de la recherche"} />
                    )}
                    {exportData && (
                        <SubTitle content={"Critères de l'export"} />
                    )}
                </Flex>
                <EmployeesDropdown column>
                    {filterData && (
                        <InputLabel
                            content={
                                "Collaborateur(s) ou département(s) concerné(s)"
                            }
                        />
                    )}
                    {exportData && (
                        <InputLabel
                            content={
                                "Collaborateur(s) ou département(s) concerné(s)"
                            }
                        />
                    )}
                    {is_loading_employees ? (
                        <Flex>
                            <Loader
                                size="small"
                                label={"chargement des collaborateurs"}
                                labelPosition="end"
                            />
                        </Flex>
                    ) : (
                        <Controller
                            name="employee"
                            control={control}
                            render={({ field: { onChange, value, ref } }) => {
                                return (
                                    <DropdownEmployees
                                        items={formated_employees}
                                        placeholder={
                                            filterData
                                                ? "Choisir les personne(s) ou département(s) concernée(s)"
                                                : "Tous les collaborateurs"
                                        }
                                        noResultsMessage="Aucun résultat."
                                        positionFixed={true}
                                        renderSelectedItem={() => null}
                                        onChange={(e, i) => {
                                            onChange(i?.value);
                                        }}
                                        value={value}
                                        ref={ref}
                                        multiple
                                        search
                                    />
                                );
                            }}
                        />
                    )}
                    <PillListEmployeeSelected
                        selectedEmployees={watch_employees}
                    />
                </EmployeesDropdown>
                <Flex column>
                    <Flex column>
                        <InputLabel
                            content={
                                isManager
                                    ? "Statut(s) des demandes"
                                    : "Opération(s) effectuée(s)"
                            }
                        />
                    </Flex>
                    {!isManager && !isHR && (
                        <Flex column>
                            <Checkbox
                                label="Tous"
                                onChange={selectAllOrDeselectAll}
                                checked={
                                    watch_checkboxes.length ===
                                    TYPES_OPERATION_AJUSTMENT.length
                                        ? true
                                        : watch_checkboxes.length > 0
                                        ? "mixed"
                                        : false
                                }
                            />
                            <WrapperCheckboxes gap="gap.small">
                                {TYPES_OPERATION_AJUSTMENT.map((type) => {
                                    return (
                                        <Checkbox
                                            key={type.id}
                                            onChange={(e, { checked }) =>
                                                addOrRemoveChecked(
                                                    type.id,
                                                    checked
                                                )
                                            }
                                            checked={watch_checkboxes.includes(
                                                type.id
                                            )}
                                            label={type.name}
                                        />
                                    );
                                })}
                            </WrapperCheckboxes>
                        </Flex>
                    )}
                    {(isManager || isHR) && (
                        <Flex column>
                            <Checkbox
                                label="Toutes"
                                onChange={selectAllOrDeselectAllStatus}
                                checked={
                                    watch_checkboxes_status.length ===
                                    TYPES_STATUS_LEAVE.length
                                        ? true
                                        : watch_checkboxes_status.length > 0
                                        ? "mixed"
                                        : false
                                }
                            />
                            <WrapperCheckboxes gap="gap.small">
                                {TYPES_STATUS_LEAVE.map((type) => {
                                    return (
                                        <Checkbox
                                            key={type.id}
                                            onChange={(e, { checked }) =>
                                                addOrRemoveCheckedStatus(
                                                    type.id,
                                                    checked
                                                )
                                            }
                                            checked={watch_checkboxes_status.includes(
                                                type.id
                                            )}
                                            label={type.name}
                                        />
                                    );
                                })}
                            </WrapperCheckboxes>
                        </Flex>
                    )}
                </Flex>
            </Flex>
            <Flex column>
                <InputLabel
                    content={
                        filterData
                            ? "Type(s) de solde ajusté"
                            : "Type(s) d'absence"
                    }
                />
                <Controller
                    name="type"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => {
                        return (
                            <DropdownTypeSolde
                                multiple
                                search
                                items={format_type}
                                placeholder={
                                    filterData
                                        ? "Choisir un ou plusieurs type(s) d'absence dans la liste"
                                        : "Tous les types"
                                }
                                noResultsMessage="Aucun résultat."
                                positionFixed={true}
                                renderSelectedItem={() => null}
                                onChange={(e, i) => {
                                    onChange(i?.value);
                                }}
                                value={value}
                                ref={ref}
                            />
                        );
                    }}
                />
                <PillListTypeSelected watch_type={watch_type} />
            </Flex>
        </Flex>
    );
};

export default ContentFilterAjustment;
