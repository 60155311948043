import React from "react";
import { Wrapper } from "./index.style";
import Slot from "../Slot";
import Break from "../Break";
import StripedTypeLeave from "../StripedTypeLeave";

const DaySlots = ({ shifts, value_day_type, status_day, data_day }) => {
    const is_worked_day = value_day_type === 0;
    const status_format =
        status_day === 0 || status_day === 1
            ? "default"
            : status_day === 2
            ? "saved_or_sent"
            : "validated";

    const isLeave = data_day?.type?.value === 2;

    return (
        <Wrapper column space="evenly" gap="gap.medium" hAlign="center">
            {isLeave ? (
                <StripedTypeLeave
                    leave_name={data_day?.type?.leave_name}
                    background_color={data_day?.type?.leave_color + "50"}
                    striped_color={data_day?.type?.leave_color}
                />
            ) : (
                shifts.map((shift) => {
                    const isBreak = shift?.type?.value === -1;
                    const isLeave = shift?.type?.value === 1;
                    if (isBreak) return <Break duration={shift.duration} />;
                    if (isLeave)
                        return (
                            <StripedTypeLeave
                                leave_name={shift.type.leave_name}
                                background_color={shift.type.leave_color + "50"}
                                striped_color={shift.type.leave_color}
                                is_half_day
                            />
                        );
                    if (is_worked_day)
                        return (
                            <Slot
                                status={status_format}
                                start_time={shift.start_time}
                                end_time={shift.end_time}
                                duration={shift.duration_str}
                            />
                        );
                })
            )}
        </Wrapper>
    );
};

export default DaySlots;
