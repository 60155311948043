import {
    ApprovalsAppbarIcon,
    Avatar,
    Button,
    Checkbox,
    CloseIcon,
    Dialog,
    Flex,
    Loader,
    Skeleton,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import { BADGE_STATUS } from "../../BadgeStatus";
import HourBadge from "../HourBadge";
import HourCounter from "../HourBadge/HourCounter";
import { Beach16Regular, OpenRegular } from "@fluentui/react-icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
    _api_get_detail_dialog,
    _api_post_validate_time_reporting,
} from "../../../api";
import ContentDetailMonth from "../../ContentDetailMonth/ContentDetailMonth";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../../constants";
import BadgesStatus from "../../BadgesStatus/BadgesStatus";
import StatusLeaveDetail from "../components/StatusLeaveDetail";
import useToast from "../../../../../hooks/useToast";
import { useToastGlobal } from "../../../../../stores/useToastGlobal";

const TimeReportToValidate = ({
    users,
    loading,
    userIdChecked,
    userIdToSend,
    status,
    highlightedColumn,
    currentMonth,
}) => {
    const isStatusIncomplete = status === BADGE_STATUS.Incomplete;
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const toastConfig = useToastGlobal((state) => state);

    const [open_detail_dialog, setOpenDetailDialog] = useState({
        visible: false,
        id_user: null,
    });

    const queryClient = useQueryClient();

    const { isLoading, data, isFetching } = useQuery(
        ["TIME_REPORTING::get_detail_dialog", open_detail_dialog.id_user],
        () => {
            return _api_get_detail_dialog({
                month_index: currentMonth.index + 1,
                user_id: open_detail_dialog?.id_user,
                year: currentMonth.year,
            });
        },
        {
            enabled: open_detail_dialog.id_user !== null,
            refetchOnWindowFocus: false,
        }
    );

    const { isLoading: isLoadingMutate, mutate } = useMutation((data) => {
        return _api_post_validate_time_reporting(data);
    });

    const on_close = () => {
        setOpenDetailDialog({
            id_user: null,
            visible: false,
        });
    };

    const onAcceptOrRefuse = ({ type, users, message = "" }) => {
        mutate(
            {
                users,
                type,
                currentMonth,
            },
            {
                onSuccess: (data) => {
                    if (data?.error_count > 0) {
                        return alert(data?.error_messages?.at(0));
                    }
                    on_close(); // fermer la modale detail
                    queryClient.invalidateQueries("get_all_data_for_my_team"); // refresh the global query
                    toastConfig.setShow({
                        visible: true,
                        content: message,
                        level: "success",
                    });
                },
            }
        );
        return;
    };

    return users?.map((el) => {
        const default_checked = userIdChecked?.includes(el?.id);
        let status_badge = status;
        const IS_STATUS_SENT_TO_CLIENT = el?.summary?.status?.value === 4;

        if (isStatusIncomplete) {
            const default_status = el?.summary?.status?.value;
            status_badge =
                default_status === 0
                    ? BADGE_STATUS.Incomplete
                    : default_status === 2
                    ? BADGE_STATUS.Refused
                    : BADGE_STATUS.Sent;
        }
        const total_leaves = el?.summary?.total_leaves;

        const styles_refuse_btn = {
            backgroundColor: "var(--color-danger)",
            color: "white",
            ":hover": {
                backgroundColor: "#b12b43",
                color: "white",
            },
        };

        return (
            <Row
                fill
                column
                vAlign="center"
                styles={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #EBEBEB",
                    height: "88px",
                }}
            >
                <Flex
                    vAlign="center"
                    styles={{
                        gap: "16px",
                        minHeight: "60px",
                    }}
                >
                    <Flex fill styles={{ gap: "20px" }} vAlign="center">
                        {loading ? (
                            <Skeleton
                                styles={{
                                    minHeight: "24px",
                                    width: "428px !important",
                                    paddingTop: "4px",
                                }}
                                animation="pulse"
                            >
                                <Skeleton.Line />
                            </Skeleton>
                        ) : (
                            <Flex
                                styles={{
                                    backgroundColor: "white",
                                    minWidth: "320px",
                                    padding: "12px 20px",
                                }}
                                vAlign="center"
                            >
                                <Checkbox
                                    checked={default_checked}
                                    onChange={() => userIdToSend(el?.id)}
                                />

                                <Flex vAlign="center" gap="gap.small" fill>
                                    <Avatar
                                        size="small"
                                        image={el?.avatar}
                                        name={el?.name}
                                    />
                                    <CollaboratorName content={el?.name} />
                                    <Flex
                                        vAlign="center"
                                        gap="gap.smaller"
                                        styles={{ marginLeft: "auto" }}
                                    >
                                        {IS_STATUS_SENT_TO_CLIENT && (
                                            <ApprovalsAppbarIcon
                                                outline
                                                color="#212121"
                                            />
                                        )}
                                        {/* {total_leaves > 0 && (
                                            <Tooltip
                                                trigger={<Beach16Regular />}
                                                subtle={false}
                                                content={`Congés : ${total_leaves} h`}
                                                pointing
                                            />
                                        )} */}

                                        <Dialog
                                            open={
                                                open_detail_dialog &&
                                                open_detail_dialog.id_user ===
                                                    el?.id
                                            }
                                            trigger={
                                                <OpenRegular
                                                    fontSize={20}
                                                    color="var(--color-primary)"
                                                    onClick={() => {
                                                        setOpenDetailDialog({
                                                            id_user: el?.id,
                                                            visible: true,
                                                        });
                                                        return;
                                                    }}
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            }
                                            header={t(
                                                "v2.home.dialogs.titles.question_send_month_to_validation",
                                                {
                                                    month_name:
                                                        currentMonth.name_month +
                                                        " " +
                                                        currentMonth.year,
                                                }
                                            )}
                                            headerAction={{
                                                icon: <CloseIcon />,
                                                onClick: () => {
                                                    on_close();
                                                },
                                            }}
                                            content={
                                                isLoading || isFetching ? (
                                                    <Loader labelPosition="Chargement ..." />
                                                ) : (
                                                    <Flex column>
                                                        <Flex space="between">
                                                            <Flex
                                                                gap="gap.medium"
                                                                vAlign="center"
                                                                styles={{
                                                                    paddingTop:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <Avatar
                                                                    image={
                                                                        data
                                                                            ?.details
                                                                            ?.user
                                                                            ?.avatar
                                                                    }
                                                                    name={
                                                                        data
                                                                            ?.details
                                                                            ?.user
                                                                            ?.name
                                                                    }
                                                                />
                                                                <Text
                                                                    content={
                                                                        data
                                                                            ?.details
                                                                            ?.user
                                                                            ?.name
                                                                    }
                                                                    weight="regular"
                                                                    size="large"
                                                                />
                                                            </Flex>
                                                            {/* section a droite */}
                                                            {IS_STATUS_SENT_TO_CLIENT && (
                                                                <StatusLeaveDetail
                                                                    user_id={
                                                                        data
                                                                            ?.details
                                                                            ?.user
                                                                            ?.id
                                                                    }
                                                                    isLoading={
                                                                        isLoadingMutate
                                                                    }
                                                                    onAcceptOrRefuse={
                                                                        onAcceptOrRefuse
                                                                    }
                                                                    date={
                                                                        data
                                                                            ?.details
                                                                            ?.status
                                                                            ?.external_approval_email_sent_on
                                                                    }
                                                                    email={
                                                                        data
                                                                            ?.details
                                                                            ?.status
                                                                            ?.external_manager_email
                                                                    }
                                                                />
                                                            )}
                                                        </Flex>
                                                        <ContentDetailMonth
                                                            special_days={
                                                                data?.details
                                                                    ?.special_days
                                                            }
                                                            declared_hours_count={
                                                                data?.details
                                                                    ?.declared_hours_count
                                                            }
                                                            difference={
                                                                data?.details
                                                                    ?.difference
                                                            }
                                                            expected_hours_count={
                                                                data?.details
                                                                    ?.expected_hours_count
                                                            }
                                                            weeks={
                                                                data?.details
                                                                    ?.weeks
                                                            }
                                                        />
                                                        <Flex
                                                            space="between"
                                                            styles={{
                                                                marginTop:
                                                                    "70px",
                                                            }}
                                                        >
                                                            <Button
                                                                content="Fermer"
                                                                onClick={() => {
                                                                    on_close();
                                                                }}
                                                                flat
                                                            />
                                                            {IS_STATUS_SENT_TO_CLIENT && (
                                                                <Button
                                                                    content="Relancer pour validation"
                                                                    loading={
                                                                        isLoadingMutate
                                                                    }
                                                                    primary
                                                                    onClick={() => {
                                                                        onAcceptOrRefuse(
                                                                            {
                                                                                type: "remind",
                                                                                users: [
                                                                                    data
                                                                                        ?.details
                                                                                        ?.user
                                                                                        ?.id,
                                                                                ],
                                                                                message:
                                                                                    "Relance envoyée avec succès",
                                                                            }
                                                                        );
                                                                    }}
                                                                    flat
                                                                />
                                                            )}
                                                            {data?.details
                                                                ?.user_can_approve && (
                                                                <Flex gap="gap.medium">
                                                                    <Button
                                                                        content="Refuser la feuille de temps"
                                                                        onClick={() => {
                                                                            onAcceptOrRefuse(
                                                                                {
                                                                                    type: "reject",
                                                                                    users: [
                                                                                        data
                                                                                            ?.details
                                                                                            ?.user
                                                                                            ?.id,
                                                                                    ],
                                                                                    message:
                                                                                        "Feuille de temps refusée",
                                                                                }
                                                                            );
                                                                        }}
                                                                        flat
                                                                        styles={
                                                                            styles_refuse_btn
                                                                        }
                                                                    />
                                                                    <Button
                                                                        content="Valider la feuille de temps"
                                                                        loading={
                                                                            isLoadingMutate
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.preventDefault();
                                                                            onAcceptOrRefuse(
                                                                                {
                                                                                    type: "approve",
                                                                                    users: [
                                                                                        data
                                                                                            ?.details
                                                                                            ?.user
                                                                                            ?.id,
                                                                                    ],
                                                                                    message:
                                                                                        "Feuille de temps approuvée",
                                                                                }
                                                                            );
                                                                        }}
                                                                        flat
                                                                        primary
                                                                    />
                                                                </Flex>
                                                            )}
                                                        </Flex>
                                                    </Flex>
                                                )
                                            }
                                            onCancel={() => {
                                                on_close();
                                            }}
                                            styles={{
                                                width: "764px",
                                                height: "fit-content",
                                                paddingBottom: 0,
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}

                        <Flex
                            vAlign="center"
                            fill
                            space="between"
                            styles={{
                                height: "60px",
                            }}
                        >
                            {el?.summary?.weeks?.map((week, index) => {
                                return (
                                    <Flex column>
                                        <BadgesStatus
                                            comment={week?.comments}
                                            leave={week?.total_leaves}
                                        />
                                        <HourBadge
                                            total_leaves={week?.total_leaves}
                                            loading={loading}
                                            timeWorked={week?.hours_count}
                                            addionalHourWorkedBadge={
                                                week?.difference
                                            }
                                            index={index}
                                            highlightedColumn={
                                                highlightedColumn
                                            }
                                        />
                                    </Flex>
                                );
                            })}
                        </Flex>
                    </Flex>
                    {/* total  */}
                    {loading ? (
                        <Skeleton
                            styles={{
                                minHeight: "24px",
                                width: "160px !important",
                                paddingTop: "4px",
                            }}
                            animation="pulse"
                        >
                            <Skeleton.Line

                            // size="larger"
                            />
                        </Skeleton>
                    ) : (
                        <TotalFlex hAlign="center" vAlign="center">
                            <TotalText content={`${el?.summary?.total} h`} />
                            {el?.summary?.total ? (
                                <HourCounter
                                    status={
                                        el?.summary?.total > 0
                                            ? "positive"
                                            : "negative"
                                    }
                                    addionalHourWorkedBadge={
                                        el?.summary?.difference
                                    }
                                />
                            ) : null}
                            {/* {total_leaves !== 0 && (
                                <Tooltip
                                    trigger={<Beach16Regular />}
                                    subtle={false}
                                    content={`Total congés du mois : ${total_leaves} h`}
                                    pointing
                                />
                            )} */}
                        </TotalFlex>
                    )}
                </Flex>
                {/* divider */}
                {/* <Box styles={{ minHeight: "20px", background: "#E8EBFA" }} /> */}
            </Row>
        );
    });
};

export default TimeReportToValidate;

const Row = styled(Flex)`
    /* border: 5px solid yellow; */
    /* height: 65px; */
    border-left: 4px solid transparent;
    transition: all 0.3s;
    :hover {
        transition: all 0.3s;
        border-left: 4px solid #9299f7;
    }
`;

const CollaboratorName = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #242424;

    /* Medium/600 */

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    width: 120px;
    /* height: 20px; */
`;

const TotalFlex = styled(Flex)`
    padding: 8px;
    gap: 8px;
    min-width: 163px;
    background: #fff;

    /* shadow-2 */
    /* box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
        0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11); */
`;

const TotalText = styled(Text)`
    color: #242424;
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
`;
