import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import { BASE_URL_ILLUSTRATIONS } from "../../../constants";

import {
    EmptyTaskDescription,
    EmptyTasksTitle,
    TitleMyTask,
    WrapperMytask,
} from "../style";
import ViewTable from "../ViewTable";

const MyTasks = ({ TabView, onChange, tasks, refetchGetAllTasks }) => {
    return (
        <>
            {tasks?.length !== 0 ? (
                <>
                    {/* tabs */}
                    {/* array list */}
                    <WrapperMytask column fill>
                        <TitleMyTask content="Liste de mes tâches" />
                        <ViewTable tasks={tasks} />
                    </WrapperMytask>
                </>
            ) : (
                <Flex
                    column
                    fill
                    vAlign="center"
                    hAlign="center"
                    gap="gap.large"
                >
                    <Flex>
                        <Image
                            src={`${BASE_URL_ILLUSTRATIONS}/No-task-todo.svg`}
                            alt="empty tasks"
                        />
                    </Flex>
                    <Flex
                        column
                        vAlign="center"
                        hAlign="center"
                        gap="gap.small"
                    >
                        <EmptyTasksTitle>
                            Aucune tâche à effectuer
                        </EmptyTasksTitle>
                        <EmptyTaskDescription>
                            Vous n’avez pas de tâche à faire,
                            <br />
                            c’est parfait !
                        </EmptyTaskDescription>
                    </Flex>
                </Flex>
            )}
        </>
    );
};

export default MyTasks;
