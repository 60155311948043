import React from "react";
import { PillContent, Wrapper } from "./index.style";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { useTranslation } from "react-i18next";
import { Flex } from "@fluentui/react-northstar";

const Pills = ({ is_holiday = false }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Flex fill vAlign="center" hAlign="center">
            <Wrapper is_holiday={is_holiday}>
                <PillContent
                    content={
                        is_holiday
                            ? t("v2.home.pills.holiday")
                            : t("v2.home.pills.empty_slots")
                    }
                />
            </Wrapper>
        </Flex>
    );
};

export default Pills;
