import {
    Alert,
    Avatar,
    Button,
    ContactCardIcon,
    Divider,
    Flex,
    Image,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { Container, ContainerFluid } from "../../../common/styles";
import styled, { keyframes } from "styled-components/macro";
import WelcomeImg from "../img/bgBulle.svg";
import { Copy24Regular } from "@fluentui/react-icons";
import Footer from "./Footer";
import DocumentComponent from "./DocumentComponent";
import { _preonboarding } from "../api";
import { useQuery } from "react-query";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import logo_yeetch from "../../../assets/logos/logo.svg";
import { DEVICE } from "../../../constants/responsive";
import MobileFooter from "./MobileFooter";

const WelcomePreonboarding = () => {
    const [copied, setCopyToClipBoard] = useCopyToClipboard("");

    const copiedToClipboard = (Contact_Hr) => {
        return setCopyToClipBoard(Contact_Hr);
    };

    const { isLoading, isFetching, data } = useQuery(
        ["preonboarding_information"],
        () => _preonboarding()
    );

    return (
        <ContainerFluid fill column>
            <ContainerStyle fill hAlign="center" styles={{ gap: "40px" }}>
                <LeftSide column>
                    <Header vAlign="center" fill space="between">
                        {/* <Logo src={yeetch_logo} /> */}
                        <Logo src={logo_yeetch} />
                        {data?.contract_document_url && (
                            <Button
                                flat
                                icon={<ContactCardIcon outline />}
                                content="Afficher mon contrat"
                            />
                        )}
                    </Header>
                    <Content column>
                        {/* bonjour Text  */}
                        <Flex>
                            <BonjourText
                                content={`Bonjour ${data?.me?.first_name} 😊`}
                            />
                        </Flex>
                        {/* description text  */}
                        <Flex fill column gap="gap.medium">
                            <DescriptionText
                                content={`Si vous êtes ici, c'est que vous allez rejoindre nos équipes ${data?.me?.company_name}, félicitations !`}
                            />
                            <DescriptionText
                                content={
                                    <>
                                        Votre RH vous a{" "}
                                        <b>assigné des actions à réaliser</b>{" "}
                                        afin de vous guider dans la{" "}
                                        <b>
                                            finalisation de votre dossier
                                            administratif.
                                        </b>
                                    </>
                                }
                            />
                        </Flex>
                        <ImageStyle src={WelcomeImg} />
                        <DividerStyle />
                        <RhContactStyle space="between" vAlign="center">
                            <LeftSideCard>
                                <Avatar
                                    image={data?.hr?.avatar}
                                    name={data?.hr?.name}
                                />
                                <Flex column>
                                    <Name content={data?.hr?.name} />
                                    <JobTitle content={data?.hr?.job_title} />
                                </Flex>
                            </LeftSideCard>
                            <RightSideCard column>
                                <Flex
                                    space="between"
                                    vAlign="center"
                                    gap="gap.small"
                                >
                                    <TelNumber
                                        content={
                                            data?.hr?.phone
                                                ? data?.hr?.phone
                                                : "Non renseigné"
                                        }
                                    />
                                    <Copy24Regular
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            copiedToClipboard(data?.hr?.phone)
                                        }
                                    />
                                </Flex>
                                <Flex
                                    space="between"
                                    vAlign="center"
                                    gap="gap.small"
                                >
                                    <EmailText content={data?.hr?.email} />
                                    <Copy24Regular
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            copiedToClipboard(data?.hr?.email)
                                        }
                                    />
                                </Flex>
                                {copied && (
                                    <AlertCopied
                                        copied={data?.hr?.email}
                                        content={"Élément copié !"}
                                        className={
                                            copied
                                                ? "fade-in-right"
                                                : "fade-out-right"
                                        }
                                    />
                                )}
                            </RightSideCard>
                        </RhContactStyle>
                    </Content>
                </LeftSide>

                <RightSide column>
                    <DocumentComponent
                        data={data}
                        isLoading={isLoading}
                        isFetching={isFetching}
                    />
                </RightSide>
            </ContainerStyle>
            <ContainerDesktop hAlign="center">
                <Footer data={data} />
            </ContainerDesktop>
            <RhContactMobileFlex
                styles={{
                    // paddingLeft: "24px",
                    gap: "16px",
                }}
                column
                hAlign="center"
                fill
            >
                <RhContactMobile>
                    <Avatar image={data?.hr?.avatar} name={data?.hr?.name} />
                    <Flex styles={{ paddingBlock: "16px" }} column>
                        <Name content={data?.hr?.name} />
                        <JobTitle content={data?.hr?.job_title} />
                    </Flex>
                </RhContactMobile>
                <Flex styles={{ width: "272px" }} column>
                    <Flex space="between" vAlign="center" gap="gap.small">
                        <TelNumber
                            content={
                                data?.hr?.phone
                                    ? data?.hr?.phone
                                    : "Non renseigné"
                            }
                        />
                        <Copy24Regular
                            style={{ cursor: "pointer" }}
                            onClick={() => copiedToClipboard(data?.hr?.phone)}
                        />
                    </Flex>
                    <Flex space="between" vAlign="center" gap="gap.small">
                        <EmailText content={data?.hr?.email} />
                        <Copy24Regular
                            style={{ cursor: "pointer" }}
                            onClick={() => copiedToClipboard(data?.hr?.email)}
                        />
                    </Flex>
                    {copied && (
                        <AlertCopied
                            copied={data?.hr?.email}
                            content={"Élément copié !"}
                            className={
                                copied ? "fade-in-right" : "fade-out-right"
                            }
                        />
                    )}
                </Flex>
                <Divider
                    style={{
                        width: "100%",
                        height: "1px",
                    }}
                />
                <MobileFooter />
            </RhContactMobileFlex>
        </ContainerFluid>
    );
};

export default WelcomePreonboarding;

const ContainerDesktop = styled(Container)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.mobileM} {
        display: none;
    }
    @media ${DEVICE.mobileL} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        /* justify-content: center; */
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

const RhContactMobileFlex = styled(Flex)`
    @media ${DEVICE.mobileS} {
        justify-content: center;
    }
    @media ${DEVICE.mobileM} {
        justify-content: center;
    }
    @media ${DEVICE.mobileL} {
        align-items: center;
    }
    @media ${DEVICE.tablet} {
        /* justify-content: center; */
        align-items: center;
    }
    @media ${DEVICE.laptop} {
        justify-content: center;
        display: none;
    }
`;

const RhContactMobile = styled(Flex)`
    gap: 16px;
    margin-top: 24px;

    @media ${DEVICE.mobileS} {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background: #fff;
        padding: 16px 20px;
        width: 274px;
        height: 72px;
        /* shadow-2 */
        box-shadow: 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13),
            0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11);
    }
    @media ${DEVICE.mobileM} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.mobileL} {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.tablet} {
        justify-content: center;
        align-items: center;
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;

const ContainerStyle = styled(Container)`
    /* padding: 24px; */

    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.mobileL} {
        padding: 12px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;

        width: 100%;
        gap: 24px;
    }
`;

const ImageStyle = styled(Image)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;

const EmailText = styled(Text)`
    color: #0067b8;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
`;
const TelNumber = styled(Text)`
    color: #0067b8;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
`;

const RightSideCard = styled(Flex)`
    gap: 10px;
`;

const Name = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;
const JobTitle = styled(Text)`
    color: #616161;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

const LeftSideCard = styled(Flex)`
    gap: 16px;
    padding: 16px;
    width: 200px;
`;

const RhContact = styled(Flex)``;

const DescriptionText = styled(Text)`
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

const BonjourText = styled(Text)`
    color: #242424;

    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 24px;
`;

const Logo = styled(Image)`
    @media ${DEVICE.mobileS} {
        width: 82px;
        height: 24px;
    }
    @media ${DEVICE.tablet} {
        width: 173px;
        height: 56px;
    }
    @media ${DEVICE.laptop} {
        width: 173px;
        height: 56px;
    }
`;

const LeftSide = styled(Flex)`
    height: auto;
    margin-top: 24px;
    gap: 24px;
    @media ${DEVICE.mobileS} {
        width: 100%;
        padding-inline: 11px;
    }
    @media ${DEVICE.tablet} {
        width: 100%;
        padding-inline: 11px;
    }
    @media ${DEVICE.laptop} {
        max-width: 497px;

        width: 100%;
        /* padding-inline: 0px; */
    }
`;
const RightSide = styled(Flex)`
    height: auto;
    @media ${DEVICE.mobileS} {
        margin-top: 0px;
    }
    @media ${DEVICE.mobileM} {
        margin-top: 0px;
        width: 100%;
    }
    @media ${DEVICE.tablet} {
        margin-top: 24px;
        width: 490px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 24px;
    }
`;
const Header = styled(Flex)`
    max-height: 61px;
`;
const Content = styled(Flex)`
    gap: 20px;

    width: 100%;
`;

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    right : 0px;
    }
    100% {
    opacity: 1;
    right: 45%
  }
`;

const fadeOutRight = keyframes`
    0% {
        opacity: 1;
        right: 45%;
    }
    100% {
        opacity: 0;
        right: 50px;
    }
`;

const AlertCopied = styled(Alert)`
    position: absolute;
    top: 50%;
    background-color: black;
    color: white;
    font-weight: 600;
    height: 36px;

    opacity: 0;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;

    &.fade-in-right {
        animation-name: ${fadeInRight};
    }

    &.fade-out-right {
        animation-name: ${fadeOutRight};
    }
`;
const RhContactStyle = styled(RhContact)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;
const DividerStyle = styled(Divider)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;
