import { Box, Flex, Skeleton, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import {
    BackToText,
    HeaderText,
    Body,
    Header,
    TextStyle,
    HeaderBody,
} from "./index.style";
import SearchBarEmployeeList from "../../components/SearchBarEmployee";
import WeekTableHeader from "../../components/WeekTableHeader";
import WeekRow from "../../components/WeekRow/WeekRow";
import styled from "styled-components";
import { useQuery } from "react-query";
import { _api_get_weekly_tracked_data } from "../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import SliderWeeks from "../../components/Sliders/SliderWeeks";
import moment from "moment";
import LoadingData from "./LoadingData";

const Week = () => {
    const [params, setParams] = useSearchParams();
    const default_iso_week = params.get("week");
    const default_year = params.get("year") ?? moment().get("year");
    const start_date_week = moment()
        .set("year", Number(default_year))
        .isoWeek(default_iso_week)
        .isoWeekday(1)
        .format("YYYY-MM-DD");

    const [state, setState] = useState({
        iso_week: default_iso_week,
        year: default_year,
        start_date_week,
    });

    const [filtredRowsByUser, setFiltredRowsByUser] = useState([]);
    const navigate = useNavigate();

    const {
        isFetching,
        isLoading,
        data: weeklydata,
    } = useQuery(
        ["get_weekly_tracked_data", state?.start_date_week ?? start_date_week],
        () =>
            _api_get_weekly_tracked_data({
                date: state.start_date_week,
            }),
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                return setFiltredRowsByUser(data);
            },
        }
    );

    const onUserSelected = (id_user) => {
        if (!id_user) {
            return setFiltredRowsByUser(weeklydata);
        }

        const filteredUsers = weeklydata.users?.filter(
            (user) => user?.id === id_user
        );

        setFiltredRowsByUser((state) => {
            return {
                ...state,
                users: filteredUsers,
            };
        });
        return;
    };

    const isLoadingData = isLoading || isFetching;
    const HeaderWeek = weeklydata?.days;
    const monthIndex = params.get("month");
    const yearNumber = params.get("year");

    const backLinkClick = () => {
        // return navigate(-1);
        return navigate(
            `/time_reporting/team_tracking?month=${monthIndex}&year=${yearNumber}`
        );
    };

    return (
        <Flex fill column>
            {/* header */}
            <Header fill vAlign="center" space="between">
                <Flex>
                    <HeaderText content="Suivi hebdomadaire" />
                </Flex>
                <Flex>
                    <BackToText
                        onClick={backLinkClick}
                        text
                        content="Retour vue mensuelle"
                    />
                </Flex>
            </Header>
            <Body fill column>
                {/* slider week */}
                <Flex>
                    <SliderWeeks
                        default_iso_week={default_iso_week}
                        default_year={default_year}
                        onChangeWeek={(state) => {
                            setState(state);
                            return;
                        }}
                    />
                </Flex>
                <HeaderBody vAlign="center">
                    <Flex styles={{ gap: "35px" }}>
                        {/* search bar  */}
                        <Flex styles={{ minWidth: "280px" }}>
                            <SearchBarEmployeeList
                                users={weeklydata?.users}
                                onChangeUser={(id_user) => {
                                    return onUserSelected(id_user);
                                }}
                            />
                        </Flex>
                        {/* week date  */}
                        <Flex
                            hAlign="center"
                            space="between"
                            styles={{
                                width: "902px",
                                // gap: "8px",
                            }}
                            fill
                        >
                            {isLoadingData ? (
                                <LoadingData />
                            ) : (
                                HeaderWeek?.map((day) => {
                                    return (
                                        <WeekTableHeader
                                            day={day}
                                            // loading={true}
                                            $noStyle={true}
                                        />
                                    );
                                })
                            )}
                        </Flex>
                    </Flex>
                    <TotalText content="Total effectif" />
                </HeaderBody>
            </Body>
            <BodyWeekFlex>
                <WeekRow weeklydata={filtredRowsByUser} />
            </BodyWeekFlex>
        </Flex>
    );
};

export default Week;

const TotalText = styled(Text)`
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

const BodyWeekFlex = styled(Box)`
    max-height: 390px;
    overflow-x: hidden;
    overflow-y: scroll;
`;
