import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components/macro";
import { Typography } from "@mui/material";

import IllustrationIcon from "../../assets/icons/documents/icons_documents/illustration_dropzone.svg";
import { _api_upload_document } from "../../api/manage_document";
import { FILE_SIZE_LIMIT } from "../../features/documents/constants";

import PropTypes from "prop-types";
import { ArrowRightIcon, Attachment, Flex } from "@fluentui/react-northstar";
import { FilesPdfIcon, TrashCanIcon } from "@fluentui/react-northstar";
import { _api_delete_document, _upload_file } from "../../api/tasks";
import { file_size_format } from "../../constants/maths";
import Illustration from "../shared/Illustration";
import { AttachmentStyle } from "../../pages/DashboardTasks/style";
import Toast from "../Alerts";
import useToast from "../../hooks/useToast";

const CustomDropzone = ({
    savedFiles,
    setSavedFiles,
    folderName = "",
    apiType = "tmp",
    maxFiles = 5,
    setShowSnackbar,
    showInformationFile = true,
    oneFile = false,
    size_limit = FILE_SIZE_LIMIT,
    showAttachmentActionsButton = true /** this used for showing action on attachement button or not */,
    // for PSPDFKIT
    documentToPreview = null,
    isPreviewDocument = false,
    onClickToPreview = () => console.log("clicked to preview it"),
}) => {
    const [toastConfig, setToastConfig] = useToast();

    const _removeFileFromList = async (indexFile, el, taskId) => {
        const response = await _api_delete_document({
            task_id: taskId,
            document_id: el.id,
        });

        if (response?.res?.status === 200) {
            const newArray = savedFiles.filter(
                (el, index) => index !== indexFile
            );
            setSavedFiles(newArray);
        } else {
            // error
            return alert(
                "Une erreur est survenue lors de la suppression du document !"
            );
        }
        return;
    };

    const onDrop = useCallback(
        async (acceptedFiles) => {
            console.log({ fileRejections });

            if (!acceptedFiles?.length) {
                return console.log("empty array");
            }
            // limit the import to 5 files at once.
            if (acceptedFiles.length > 5) {
                if (setShowSnackbar) {
                    setShowSnackbar(
                        "Vous avez dépasser la limite autorisée ( 5 fichiers à la fois )"
                    );
                }
                return;
            }
            // Do something with the files
            let formData = new FormData();
            for (let i = 0; i < acceptedFiles.length; i++) {
                const file = acceptedFiles[i];
                if (file.size > FILE_SIZE_LIMIT) {
                    // s'il y a un Snackbar props alors afficher l'alert.
                    if (setShowSnackbar) {
                        setShowSnackbar(
                            "'" +
                                file.name +
                                "' à été retirer a cause de sa taille !"
                        );
                    }
                    return false;
                }
                formData.append("uploaded_files", file);
            }

            let savedFiles;
            switch (apiType) {
                case "tmp":
                    savedFiles = await _upload_file(formData);
                    break;
                case "document":
                    savedFiles = await _api_upload_document({
                        uploaded_files: acceptedFiles,
                        folder_name: folderName,
                    });
                    if (setShowSnackbar && !savedFiles.success) {
                        setShowSnackbar(savedFiles.message.toString());
                    }
                    break;

                default:
                    break;
            }
            setSavedFiles((prevState) => [...prevState, ...savedFiles.files]);
        },
        [setSavedFiles, folderName]
    );

    const handleClick = (message, index) => (e) => {
        e.stopPropagation();
        switch (message) {
            case "Remove":
                setSavedFiles([]);
                // _removeFileFromList(index, el, taskId);
                break;
            case "Attachment":
                window.open(index);
                break;
            default:
                break;
        }
    };

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        fileRejections,
    } = useDropzone({
        accept: {
            // Google Docs
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                [".docx"],
            "application/msword": [".doc"],
            // Google Sheets
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            "application/vnd.ms-excel": [".xls"],
            // Google Slides
            "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                [".pptx"],
            "application/vnd.ms-powerpoint": [".ppt"],
            // PDF
            "application/pdf": [".pdf"],
            // IMAGES
            "image/jpeg": [".jpeg", ".jpg"],
            "image/png": [".png"],
            "image/gif": [".gif"],
            "image/bmp": [".bmp"],
            "image/tiff": [".tif", ".tiff"],
            "image/heif": [".heif", ".heic"],
            "image/webp": [".webp"],
        },
        maxFiles,
        maxSize: size_limit,
        multiple: false,
        onDrop: onDrop,
        onDropRejected: (err) => {
            console.log({ err });
            if (err.length) {
                // y'a une erreur
                const code_error = err.at(0).errors.at(0).code;
                if (code_error === "file-invalid-type") {
                    setToastConfig((state) => ({
                        ...state,
                        visible: true,
                        content:
                            "Format non autorisé. Seuls sont autorisés les formats: .pdf, .jpg, .png, .tiff, .webp, .heic et les fichiers office.",
                        level: "danger",
                    }));
                }
                if (code_error === "too-many-files") {
                    setToastConfig((state) => ({
                        ...state,
                        visible: true,
                        content:
                            "Veuillez télécharger un seul fichier à la fois.",
                        level: "danger",
                    }));
                }
                if (code_error === "file-too-large") {
                    setToastConfig((state) => ({
                        ...state,
                        visible: true,
                        content:
                            "Le fichier est trop volumineux, il doit faire moins de 2,5 Mo.",
                        level: "danger",
                    }));
                }
            }
        },
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    return (
        <div className="container" style={{ width: "100%" }}>
            <Toast
                {...toastConfig}
                onStatusChange={() =>
                    setToastConfig((state) => ({
                        ...state,
                        visible: false,
                    }))
                }
            />
            {/* 
				if the onefile is true, then show the dropzone only if 
				the documents is empty. the DZ accept only one file and disapear.
			*/}
            {oneFile ? (
                savedFiles?.length < 1 && (
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()} />
                        {/* <PostAddIcon style={{ width: 50, height: 50 }} /> */}
                        <Illustration
                            filename={"shared/dropzone_upload_file.svg"}
                            width="120"
                        />
                        <Flex column>
                            <DragDropText>
                                Glissez-déposez un document ici ou
                            </DragDropText>
                            <DragDropTextLink>
                                cliquez pour choisir un document
                            </DragDropTextLink>
                        </Flex>
                    </div>
                )
            ) : (
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    {/* <PostAddIcon style={{ width: 50, height: 50 }} /> */}
                    {/* <img src={IllustrationIcon} alt="illustration dropzone" /> */}
                    <Illustration
                        filename={"shared/dropzone_upload_file.svg"}
                        width="120"
                    />
                    <Flex column gap="gap.small">
                        <DragDropText>
                            Glissez-déposez un document ici ou
                        </DragDropText>
                        <DragDropTextLink>
                            cliquez pour choisir un document
                        </DragDropTextLink>
                    </Flex>
                </div>
            )}
            <Informations showInformationFile={showInformationFile}>
                <Typography variant="caption">
                    Poids maximal du fichier : 2,5 Mo
                </Typography>
                <Typography variant="caption">
                    Nombre de fichiers max : 5 fichiers
                </Typography>
            </Informations>
            {savedFiles?.length > 0 && apiType !== "document" && (
                <WrapperPreview oneFile={oneFile}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "15px",
                            flexWrap: "wrap",
                        }}
                    >
                        {savedFiles?.map((el, index) => {
                            console.log({ el });
                            return (
                                <AttachmentStyle
                                    key={`attach_${index}`}
                                    icon={<FilesPdfIcon />}
                                    header={el.filename || el.name}
                                    description={file_size_format(el.filesize)}
                                    actionable
                                    action={
                                        isPreviewDocument
                                            ? {
                                                  icon: (
                                                      <ArrowRightIcon
                                                          styles={{
                                                              color: "var(--color-primary)",
                                                          }}
                                                      />
                                                  ),
                                                  onClick: () =>
                                                      onClickToPreview(
                                                          el.uploaded_doc
                                                      ),
                                                  title: "Preview",
                                              }
                                            : showAttachmentActionsButton
                                            ? {
                                                  icon: <TrashCanIcon />,
                                                  onClick: handleClick(
                                                      "Remove",
                                                      index
                                                  ),
                                                  title: "Remove",
                                              }
                                            : {}
                                    }
                                    onClick={
                                        isPreviewDocument
                                            ? () =>
                                                  onClickToPreview(
                                                      el.uploaded_doc
                                                  )
                                            : handleClick(
                                                  "Attachment",
                                                  el.attached_doc ??
                                                      el?.uploaded_doc
                                              )
                                    }
                                    styles={{
                                        border:
                                            el?.uploaded_doc ===
                                            documentToPreview
                                                ? "1px solid #9299F7"
                                                : "none",
                                    }}
                                />
                            );
                        })}
                    </div>
                </WrapperPreview>
            )}
        </div>
    );
};

CustomDropzone.propTypes = {
    showInformationFile: PropTypes.bool,
    /** use this if U want just upload one document, after that the dropzone disapear */
    oneFile: PropTypes.bool,
};

export default CustomDropzone;

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "#9299F7",
    borderStyle: "dashed",
    backgroundColor: "#E8EBFA",
    color: "#bdbdbd",
    outline: "none",
    height: "200px",
    width: "100%",
    gap: 15,
    cursor: "pointer",
    transition: "border .24s ease-in-out",
};

const focusedStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#00e676",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

const Informations = styled.div`
    display: ${({ showInformationFile }) =>
        showInformationFile ? "flex" : "none"};
    flex-direction: column;
    margin-top: 8px;
    .MuiTypography-caption {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 166%;
    }
`;

const DragDropTextLink = styled.span`
    color: #5b5fc7;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    text-decoration-line: underline;
`;

const DragDropText = styled.p`
    color: #242424;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const TypographyStyle = styled(Typography)`
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
`;

export const CloseIcon = styled.div`
    position: absolute;
    right: -10px;
    top: -8px;
    z-index: 5;
`;

export const PreviewFile = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    width: 90px;
    border-radius: 5px;
    padding: 15px 8px;
    background-color: white;
    box-shadow: 2px 2px 0px 0px #eeeeee;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.3s;
    &:hover {
        opacity: 1;
    }
    span {
        color: black;
        font-size: 12px;
        width: 100%;
        word-wrap: break-word;
        text-align: center;
    }
`;

const WrapperPreview = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: ${({ oneFile }) => (oneFile ? "0px" : "16px")};
    /* background-color: rgba(0, 0, 0, 0.02); */
    /* border: 2px dashed gray; */
`;
