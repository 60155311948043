import {
    ArrowRightIcon,
    Button,
    Divider,
    Flex,
    Image,
    Input,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import styled from "styled-components";
import SignInImg from "../img/Signin.png";

import MSLogo from "../../../assets/icons/microsoft.png";
const EmailConnection = ({ setState }) => {
    return (
        <EmailConnectionStyle column>
            <Flex hAlign="center">
                <ImageSignIn src={SignInImg} />
            </Flex>
            <MSconnect column>
                <MSConnectFlex column>
                    <MSConnectText content="Se connecter avec Microsoft®" />
                    <MSConnectDesc content="Pour bénéficier d’un maximum de fonctionnalités" />
                </MSConnectFlex>
                <MSButton href={``}>
                    <MSLogoStyle src={MSLogo} alt="MS icon" />
                    <span style={{ textTransform: "uppercase" }}>
                        Se connecter avec Microsoft
                    </span>
                </MSButton>
            </MSconnect>

            <Divider
                size={1}
                styles={{ width: "100%" }}
                content={
                    <Text
                        styles={{
                            marginInline: "-10px",
                            color: "#616161",
                            fontWeight: "400",
                            fontSize: "12px",
                            lineHeight: "16px ",
                        }}
                        content={"OU"}
                    />
                }
            />
            <ConnectionGroup fill column>
                <TextStyle content="Se connecter avec son email" />

                {/* INPUT EMAIL */}
                <Flex column fill>
                    <Input
                        fluid
                        name="email"
                        type="email"
                        label={
                            <Text
                                styles={{
                                    color: "#616161",
                                    fontWeight: "400",
                                    fontSize: "12px",
                                    lineHeight: "16px",
                                }}
                                content="Email"
                            />
                        }
                    />
                </Flex>
                {/* Button se connecter avec email */}
                <Flex fill vAlign="center" hAlign="center" column>
                    <Button
                        fluid
                        styles={{ paddingInline: "8px" }}
                        primary
                        content={
                            <Text
                                style={{ textTransform: "uppercase" }}
                                content="Se connecter avec son email"
                            />
                        }
                        icon={<ArrowRightIcon size="small" />}
                        iconPosition="after"
                        onClick={setState}
                    />
                </Flex>
            </ConnectionGroup>
        </EmailConnectionStyle>
    );
};

export default EmailConnection;

const EmailConnectionStyle = styled(Flex)`
    gap: 40px;
`;

const ImageSignIn = styled(Image)`
    width: 246px;
    height: 180px;
`;

const MSConnectText = styled(Text)`
    color: #424242;
    
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;

const MSConnectDesc = styled(Text)`
    color: #242424;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
`;
const MSConnectFlex = styled(Flex)`
    gap: 16px;
`;

const MSButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 12px;
    gap: 12px;
    background-color: white;
    font-weight: 600;
    color: #5e5e5e;
    border: 1px solid #8c8c8c;
    cursor: pointer;
    display: flex;
    width: 100%;
    transition: all 0.3s;
    text-decoration: none;
    margin-top: 15px;
    border-radius: 5px;
    span {
        font-size: 16px;
    }
    :hover {
        opacity: 0.7;
        transition: all 0.3s;
    }
`;

const MSLogoStyle = styled.img`
    width: 20px;
`;

const MSconnect = styled(Flex)`
    gap: 24px;
`;

const ConnectionGroup = styled(Flex)`
    gap: 16px;
`;

const TextStyle = styled(Text)`
    color: #424242;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`;
