import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    background-color: #fafafa;
`;
export const ContentText = styled(Text)`
    color: var(--light-theme-rest-foreground-default-foreground-2, #616161);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 350;
    line-height: 24px; /* 133.333% */
    width: calc(100% - 50px);
`;
