import { Button, DownloadIcon } from "@fluentui/react-northstar";
import { useSearchParams } from "react-router-dom";

export const ExportTrigger = ({ setOpenDialog, isMobile, searchParams }) => {
    const [CleanParams, setCleanParams] = useSearchParams();
    const CleanUrl = () => {
        setCleanParams("");
    };
    return (
        <Button
            iconPosition="after"
            icon={<DownloadIcon />}
            content={"Exporter la liste des demandes"}
            flat
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                CleanUrl();
                setOpenDialog(true);
            }}
        />
    );
};
