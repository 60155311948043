import React from "react";
import { DialogStyle } from "./style";
import { TASK_STATUS } from "../../constants/tasks";
import { useState } from "react";
import { _api_validate_document, _update_status_task } from "../../api/tasks";
import ModalDetailTask from "./ModalDetailTask";
import { CloseIcon } from "@fluentui/react-northstar";
import { useMutation } from "react-query";

/**
 * @typedef {Object} Task
 * @property {number} id - id of the task
 * @property {string} name - title of the task
 * @property {string} status  - use Global constant TASK_STATUS for enum.
 * @property {boolean} task_to_validate
 * @property {{ id : number}} assigned_to_user
 * @property {boolean} document_required
 * @property {Array<{id : number, name: string, filesize : number, filename : string, attached_doc : string }>} documents
 * @property {Array<{id : number, filesize : number, filename : string, attached_doc : string }>} documents_template
 * @property {{name: string, avatar: string, email: string }} validator
 * @property {{name: string, avatar: string, email: string }} creator
 * @property {{name: string }} category
 * @property {string} created_at - creation date.
 * @property {string} description
 * @property {string} due_date - due date
 * @property {number} document_action
 * @property {{id : number}} [detail] - id task
 * @property {{id : number}} [id] - id task
 *
 */

/**
 * @typedef {Object} PropsType
 * @property {Task} task - Object task
 * @property {boolean} hideCreationAndValidation - if true, show the Row of Validator and Created by
 * @property {ReactNode} trigger - JSX element
 * @property {(({message: string, data : Object}) => void)} [onSuccess] - Callback execute just after complete task
 * @property {(({message : string}) => void)} [onError] - Callback execute just after catch error
 *
 */

/**
 * DialogDetailTask permet de visualiser le detail d'une absence.
 * @param {PropsType} props
 * @returns {ReactNode}
 */
const DialogDetailTask = ({
    task,
    hideCreationAndValidation = false,
    trigger,
    onSuccess = () => null,
    onError = () => null,
}) => {
    const [savedFiles, setSavedFiles] = useState([]);
    const [open, setOpen] = useState(false);

    // mutations
    // * complete task
    const {
        isLoading: isMutatingCompleteTask,
        mutateAsync: mutateCompleteTask,
    } = useMutation((data) => _api_validate_document(data));

    // * update status task
    const {
        isLoading: isMutatingUpdateStatus,
        mutateAsync: mutateUpdateStatus,
    } = useMutation((data) => _update_status_task(data));

    const closeDialog = () => {
        setSavedFiles([]);
        setOpen(false);
        return;
    };

    /** use case here. */
    const _complete_task = async (task) => {
        /**
         * save the new documents of this task on local.
         */
        if (task.document_required && task.status !== TASK_STATUS.TO_APPROVE) {
            if (!savedFiles.length) {
                onError({
                    message: "Document est requis",
                });
                return;
            }
            const fetched_complete = await mutateCompleteTask({
                task_id: task.id,
                new_doc_ids: savedFiles.map((el) => el.id),
                assigned_to: task.assigned_to_user.id,
            });
        }

        // si la tache est a TODO
        if (task.status === TASK_STATUS.TODO) {
            // verifier si la tache et a valider ou pas !
            if (task.task_to_validate) {
                const response = await mutateUpdateStatus({
                    task_id: task.id,
                    task_status: TASK_STATUS.TO_APPROVE,
                });
                console.log({ response });
                closeDialog();
                if (response?.success) {
                    return onSuccess({
                        message: "La tâche a bien été envoyée",
                        data: response?.task,
                    });
                } else {
                    return onError({
                        message: response?.message,
                    });
                }
                // setToast({
                //     visible: true,
                //     content: "La tâche a bien été envoyée",
                //     level: "success",
                // });
                // reset the savedFiles
                // refetchGetAllTasksForTable();
                // return;
            }
            // sinon envoi ca a DONE
            const response = await mutateUpdateStatus({
                task_id: task.id,
                task_status: TASK_STATUS.DONE,
            });
            closeDialog();
            if (response?.success) {
                return onSuccess({
                    message: "La tâche a bien été terminée",
                    data: response?.task,
                });
            }
            return onError({
                message: response?.message,
            });
        }
        // verifier si la tache est a TO_APPROVE
        if (task.status === TASK_STATUS.TO_APPROVE) {
            // si c'est le collaborateur qui est connecte
            // alors change status to TODO directement.
            const response = await mutateUpdateStatus({
                task_id: task.id,
                task_status: TASK_STATUS.DONE,
            });
            closeDialog();
            if (response?.success) {
                return onSuccess({
                    data: response?.task,
                    message: "La tâche a bien été envoyée",
                });
            }
            return onError({
                message: response?.message,
            });
        }
    };

    /** show the value of the button According to CASE  */
    const _renderValueButton = (task) => {
        // si la tâche est a TODO
        if (task.status === TASK_STATUS.TODO) {
            // verifier si la tache et a valider ou pas !
            if (task.task_to_validate) {
                // afficher le envoyer
                return "Envoyer";
            }
            // sinon envoi ca a DONE
            // afficher terminer
            return "Terminer";
        }
        // verifier si la tache est a TO_APPROVE
        if (task.status === TASK_STATUS.TO_APPROVE) {
            // si c'est le collaborateur qui est connecte
            // alors change status to TODO directement.
            // afficher terminer ici
            return "Terminer";
        }
        return "false";
    };

    const hideButtons =
        task?.status === TASK_STATUS.DONE ||
        task?.status === TASK_STATUS.TO_APPROVE;

    let confirm_button = {
        content: _renderValueButton(task),
        disabled:
            savedFiles?.length === 0 &&
            task.document_required &&
            task.status !== TASK_STATUS.TO_APPROVE,
        loading: isMutatingCompleteTask || isMutatingUpdateStatus,
        flat: true,
    };

    return (
        <DialogStyle
            open={open}
            header={task.name}
            headerAction={{
                icon: <CloseIcon />,
                title: "Close",
                onClick: () => closeDialog(),
            }}
            content={
                <ModalDetailTask
                    data={{ ...task }}
                    savedFiles={savedFiles}
                    setSavedFiles={setSavedFiles}
                    hideCreationAndValidation={hideCreationAndValidation}
                />
            }
            trigger={<div onClick={() => setOpen(true)}>{trigger}</div>}
            cancelButton={
                hideButtons
                    ? false
                    : {
                          content: "Annuler",
                          flat: true,
                      }
            }
            confirmButton={hideButtons ? false : confirm_button}
            onConfirm={(e) => {
                e.stopPropagation();
                e.preventDefault();
                _complete_task(task);
                return;
            }}
            onCancel={closeDialog}
            onOpen={() => setSavedFiles(task?.documents)}
        />
    );
};

export default DialogDetailTask;
