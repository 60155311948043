import React from "react";
import CustomBreadCrumb from "../../../components/breadcrumb";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";
import GeneralDetailTaskContent from "./components/GeneralDetailTaskContent";

const GeneralDetailTask = () => {
    const { t } = useTranslation();
    return (
        <>
            <CustomBreadCrumb
                navList={[
                    {
                        label: t(
                            "pages.manage_modal_tasks.breadcrumb.nav_manage_task"
                        ),
                        href: ROUTES.task_manager,
                    },
                    {
                        label: t(
                            "pages.manage_modal_tasks.breadcrumb.nav_detail_task"
                        ),
                        href: "#",
                    },
                ]}
            />
            <GeneralDetailTaskContent />
        </>
    );
};

export default GeneralDetailTask;
