import React, { useEffect, useState } from "react";
import styled from "styled-components";
import validator from "validator";
import { useTranslation } from "react-i18next";
import MSLogo from "../../assets/icons/microsoft.svg";
import { BASE_URL, I18N_NS_TRANSLATION, BASE_URL_API } from "../../constants";

import {
    ArrowRightIcon,
    Button,
    Divider,
    Flex,
    Form,
    Input,
    Loader,
    Text,
} from "@fluentui/react-northstar";
import { ErrorMessage } from "../../common/styles";
import { DEVICE } from "../../constants/responsive";

import { app, authentication } from "@microsoft/teams-js";
import { useAuthStore, useAuthDetailStore } from "../../stores/useAuthStore";
import { useSearchParams } from "react-router-dom";

const InitLogin = ({
    isLoading,
    isFetching,
    email,
    error,
    setEmail,
    handleSubmit,
    refEmail,
}) => {
    //**** Implement SSO with teams ****//
    const [isLoadingSso, setIsLoadingSso] = useState(true);
    const setUser = useAuthStore((state) => state.setUser);
    const setDetailUser = useAuthDetailStore((state) => state.setDetailUser);
    console.log("setDetailUser", setDetailUser);

    const [searchParams] = useSearchParams();
    const errorParams = searchParams.get("error");

    useEffect(() => {
        // Ne sera jamais appelé en dehors de Teams
        async function initSSO() {
            try {
                await app.initialize();
                const token = await authentication.getAuthToken();
                fetch(BASE_URL_API + "/microsoft_sso_sign_in", {
                    method: "POST",
                    body: JSON.stringify({
                        token: token,
                    }),
                    headers: {
                        "Content-type": "application/json",
                    },
                    credentials: "include",
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setIsLoadingSso(false);
                        if (data?.success === true) {
                            setUser({
                                access: data.access_token,
                            });
                            setDetailUser(data.user);
                        } else {
                            // Erreur de consentement ou user not found
                            alert(data.message);
                        }
                    })
                    .catch((error) => {
                        // le front n'arrive pas à se connecter au back: cors, url etc ...
                        setIsLoadingSso(false);
                        alert(error);
                    });
            } catch (err) {
                setIsLoadingSso(false);
                console.error(err);
            }
        }
        initSSO();
        //**** End - Implement SSO with teams ****//
    }, []);

    return (
        <Flex column styles={{ width: "352px", gap: "64px" }}>
            <Flex
                styles={{ gap: "24px", paddingInline: "18px" }}
                fill
                column
                component={"form"}
                onSubmit={handleSubmit}
            >
                {isLoadingSso ? (
                    <Loader />
                ) : (
                    <>
                        {/* BUTTON se connecter a microsoft */}
                        <Flex column gap="gap.smaller">
                            <Button
                                data-testid="microsoft-init"
                                styles={{
                                    textDecoration: "none",
                                    paddingInline: "8px",
                                    textTransform: "uppercase",
                                }}
                                fluid
                                as="a"
                                href={BASE_URL + "/microsoft_sign_in"}
                                content="Se connecter avec Microsoft"
                                flat
                                icon={
                                    <MSLogoStyle src={MSLogo} alt="MS icon" />
                                }
                            />
                            <ErrorMessage
                                content={
                                    errorParams &&
                                    "une erreur est survenue, veuillez réessayer plus tard !"
                                }
                            />
                        </Flex>

                        <Flex>
                            <Divider
                                styles={{ width: "100%" }}
                                content={
                                    <Text
                                        styles={{
                                            marginInline: "-10px",
                                            color: "#616161",
                                            fontWeight: "400",
                                            fontSize: "12px",
                                            lineHeight: "16px ",
                                        }}
                                        as="p"
                                        content="Ou"
                                    />
                                }
                            />
                        </Flex>
                        {/* INPUT EMAIL + BUTTON */}
                        <Flex
                            column
                            styles={{
                                height: "fit-content",
                            }}
                        >
                            <ConnectionGroup column>
                                <Form>
                                    {/* INPUT EMAIL */}
                                    <Flex
                                        column
                                        styles={{
                                            gap: "16px",
                                            marginBottom: 0,
                                        }}
                                    >
                                        <EmailConnectText content="Se connecter avec son email" />
                                        <Flex column>
                                            <Input
                                                // styles={{ marginTop: "12px" }}
                                                fluid
                                                autoFocus
                                                name="email"
                                                type="email"
                                                label={
                                                    <Text
                                                        styles={{
                                                            color: "#616161",
                                                            fontWeight: "400",
                                                            fontSize: "12px",
                                                            lineHeight: "16px",
                                                        }}
                                                        content="Email"
                                                    />
                                                }
                                                onChange={(e) =>
                                                    setEmail(e.target.value)
                                                }
                                                value={email}
                                                error={error}
                                                ref={refEmail}
                                            />
                                            <ErrorMessage content={error} />
                                        </Flex>
                                        {/* Button se connecter avec email */}

                                        <Button
                                            fluid
                                            disabled={
                                                validator.isEmail(email)
                                                    ? false
                                                    : true
                                            }
                                            styles={{
                                                paddingInline: "8px",
                                                textTransform: "uppercase",
                                            }}
                                            onClick={handleSubmit}
                                            loading={
                                                isLoading || isFetching
                                                    ? true
                                                    : false
                                            }
                                            primary
                                            content="Se connecter avec son email"
                                            iconPosition="after"
                                        />
                                    </Flex>
                                </Form>
                            </ConnectionGroup>
                        </Flex>
                    </>
                )}
            </Flex>
        </Flex>
    );
};
export default InitLogin;
const Footer = styled(Flex)`
    /* gap: 40px; */
`;
const FooterSign = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #616161;
`;

const FooterText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0067b8;
`;
const EmailConnectText = styled(Text)`
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #424242;
`;

const ConnectionGroup = styled(Flex)`
    @media ${DEVICE.mobileS} {
        /* gap: 14px; */
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.laptop} {
    }
`;

const Footer2 = styled(Flex)`
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    color: #242424;

    @media ${DEVICE.mobileS} {
        justify-content: center;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
    @media ${DEVICE.laptop} {
        gap: 4px;
    }
`;

const MSLogoStyle = styled.img`
    width: 20px;
`;
