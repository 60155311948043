import { Flex, Pill } from "@fluentui/react-northstar";
import React from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";

const PillListTypeSelected = ({ watch_type = [] }) => {
    const { setValue } = useFormContext();
    return (
        <Flex fill wrap>
            {watch_type?.map((type) => {
                const header = type?.header;
                return (
                    <PillStyle
                        key={header}
                        onDismiss={(e, data) => {
                            setValue(
                                "type",
                                watch_type?.filter((u) => u?.header !== header)
                            );
                        }}
                        actionable
                        rectangular
                        $color={type?.color}
                    >
                        {header}
                    </PillStyle>
                );
            })}
        </Flex>
    );
};

export default PillListTypeSelected;

const PillStyle = styled(Pill)`
    border: 2px solid ${({ $color }) => ($color ? $color : "transparent")};
    background-color: white;
    min-width: fit-content;
`;
