import React from "react";
import SliderTabs from "../../../../components/shared/SliderTabs";
import { Button, CalendarIcon, Flex } from "@fluentui/react-northstar";
import styled from "styled-components";
import { Container, ContainerFluid } from "../../../../common/styles";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import frLocale from "@fullcalendar/core/locales/fr";
import timeGridPlugin from "@fullcalendar/timegrid";
import { createRef } from "react";
import { useState } from "react";
import {
    _api_approve_or_refuse_leave,
    _api_cancel_leave,
    api_team_calendar_detail,
} from "../../api";
import { useCallback } from "react";
import {
    event_content_function,
    format_events_team,
    refetchTeamBadge,
} from "../../utils";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";
import Toast from "../../../../components/Alerts";
import Timeline from "../../../../components/Timeline";
import DialogLeaveDetail from "../../../../components/Timeline/components/Dialogs/DialogLeaveDetail";
import { DEVICE } from "../../../../constants/responsive";
import { BoxStyle } from "./CollabCalendar";
import { useSearchParams } from "react-router-dom";
import LeaveRequestList from "./LeaveRequestList/LeaveRequestList";
import ManagerSoldeDetail from "./ManagerSoldeDetail";

export const INIT_CONFIG_DATA = {
    visible: false,
    data: null,
};

const ManagerAbsence = ({ holidayList }) => {
    const [params, setParams] = useSearchParams();
    const [CleanParams, setCleanParams] = useSearchParams();

    // const CleanStartDate = CleanParams.get("start_date");
    // const CleanEndDate = CleanParams.get("end_date");

    const CleanUrl = () => {
        setCleanParams("");
    };

    const calendarRef = createRef();
    const [configDetail, setConfigDetail] = useState(INIT_CONFIG_DATA);
    const [activeTab, setActiveTab] = useState(
        Number(params.get("sub_tab")) ?? 0
    );
    const [showSoldeDetail, setShowSoldeDetail] = useState(false);
    const [toastConfig, setToastConfig] = useState({
        content: "",
        visible: false,
        level: "warning",
    });

    const [currentMonth, setCurrentMonth] = useState({ month: "", year: "" });

    const showDialog = (e, events) => {
        const { id } = e.event ?? e;

        const current_data_event = events?.find(
            (el) => el?.id === parseInt(id)
        );

        setConfigDetail({ visible: true, data: current_data_event?.data });
    };

    const {
        data: teamData,
        isLoading: loadingTeamData,
        isFetching: fetchingTeamData,
        refetch: refetchTeamCall,
    } = useQuery(
        ["myCalendarTeam", currentMonth.month, currentMonth.year],
        () => api_team_calendar_detail(currentMonth.month, currentMonth.year),
        {
            refetchOnWindowFocus: true,
            refetchOnMount: true,
        }
    );

    const format_events_full_calendar_team = useCallback(() => {
        return format_events_team(teamData?.leaves || []);
    }, [teamData]);

    const events_calendar = format_events_full_calendar_team();

    const renderDayCellContent = (info) => {
        const currentCalendarDate = moment(info?.date).format("YYYY-MM-DD");
        const isHoliday = holidayList?.find(
            (holiday) => holiday?.date === currentCalendarDate
        );

        if (isHoliday) {
            return <div className="holiday">Jour férié</div>;
        }
        return;
    };

    const DisplayTeamDataIntoManagerCalendar = (arg) => {
        setCurrentMonth({
            month: arg.view.currentStart.getMonth() + 1,
            year: arg.view.currentStart.getFullYear(),
        });
    };

    const queryClient = useQueryClient();

    return (
        <ContainerFluid fill column>
            <Toast
                onStatusChange={() =>
                    setToastConfig({
                        visible: false,
                        content: "",
                        level: "warning",
                    })
                }
                level={toastConfig.level}
                content={toastConfig.content}
                visible={toastConfig.visible}
            />
            {showSoldeDetail ? (
                <ManagerSoldeDetail
                    showSoldeDetail={showSoldeDetail}
                    setShowSoldeDetail={setShowSoldeDetail}
                />
            ) : (
                <Container column>
                    <BoxStyle gap="gap.medium" column>
                        <DialogLeaveDetail
                            open={configDetail?.visible}
                            leave_details={{
                                id: configDetail?.data?.id,
                                approved_by: configDetail?.data?.approved_by,
                                authenticated_user_can_validate:
                                    configDetail?.data
                                        ?.authenticated_user_can_validate,
                                authenticated_user_can_delete:
                                    configDetail?.data
                                        ?.authenticated_user_can_delete,
                                balance_before_leave:
                                    configDetail?.data?.balance_before_leave,
                                balance_after_leave:
                                    configDetail?.data?.balance_after_leave,
                                start_date: configDetail?.data?.start_date,
                                end_date: configDetail?.data?.end_date,
                                balance_count_to_remove:
                                    configDetail?.data?.balance_count_to_remove,

                                comment: configDetail?.data?.comment,
                                documents: configDetail?.data?.documents,
                                type: {
                                    color: configDetail?.data?.type?.color,
                                    name: configDetail?.data?.type?.name,
                                    has_balance:
                                        configDetail?.data?.type?.has_balance,
                                },
                                status: {
                                    id: configDetail?.data?.status?.id,
                                },
                                request_can_be_canceled:
                                    configDetail?.data?.request_can_be_canceled,
                                requested_on: configDetail?.data?.requested_on,
                                waiting_approval_by:
                                    configDetail?.data?.waiting_approval_by,
                                last_action: {
                                    date: configDetail?.data?.last_action?.date,
                                    status: configDetail?.data?.last_action
                                        ?.status,
                                },
                                authenticated_user_can_remind_external_manager:
                                    configDetail?.data
                                        ?.authenticated_user_can_remind_external_manager,
                            }}
                            user_details={{
                                name: configDetail?.data?.user?.name,
                                id: configDetail?.data?.user?.id,
                                avatar: configDetail?.data?.user?.avatar,
                            }}
                            cbOnApproveLeave={(data) => {
                                // console.log({ data });
                                refetchTeamBadge(queryClient);
                                refetchTeamCall();
                                return setToastConfig({
                                    visible: true,
                                    content:
                                        data?.success === true
                                            ? "La demande a bien été acceptée  "
                                            : data?.message,
                                    level:
                                        data?.success === true
                                            ? "success"
                                            : "danger",
                                });
                            }}
                            cbOnCancelLeave={(data) => {
                                refetchTeamBadge(queryClient);
                                refetchTeamCall();
                                return setToastConfig({
                                    visible: true,
                                    content:
                                        data?.success === true
                                            ? "Votre demande a bien été annulée"
                                            : data?.message,
                                    level:
                                        data?.success === true
                                            ? "success"
                                            : "danger",
                                });
                            }}
                            cbOnDeleteLeave={(data) => {
                                // console.log("deleted");
                                refetchTeamBadge(queryClient);
                                refetchTeamCall();
                                return setToastConfig({
                                    visible: true,
                                    content:
                                        data?.success === true
                                            ? "La demande a bien été supprimée  "
                                            : data?.message,
                                    level:
                                        data?.success === true
                                            ? "success"
                                            : "danger",
                                });
                            }}
                            cbOnRefuseLeave={(data) => {
                                // console.log("refused");
                                refetchTeamBadge(queryClient);
                                refetchTeamCall();
                                return setToastConfig({
                                    visible: true,
                                    content:
                                        data?.success === true
                                            ? "Votre demande a bien été refusée "
                                            : data?.message,
                                    level:
                                        data?.success === true
                                            ? "success"
                                            : "danger",
                                });
                            }}
                            cbOnHideDialog={() => {
                                refetchTeamBadge(queryClient);
                                setConfigDetail(INIT_CONFIG_DATA);
                            }}
                        />
                        <FlexStyle column>
                            <Flex vAlign="center" space="between">
                                <SliderTabs
                                    tabs={[
                                        "Calendrier",
                                        "Planning",
                                        "Liste des Demandes",
                                    ]}
                                    defaultActiveIndex={Number(
                                        params.get("sub_tab")
                                    )}
                                    value={activeTab}
                                    onChange={(e) => {
                                        CleanUrl();
                                        setActiveTab(e);
                                    }}
                                />
                                <ButtonSeeSolde
                                    onClick={() =>
                                        setShowSoldeDetail(!showSoldeDetail)
                                    }
                                    icon={<CalendarIcon outline />}
                                    primary
                                    content="Voir les soldes en détail"
                                />
                            </Flex>
                            {activeTab === 0 && (
                                <Flex gap="gap.large" column>
                                    <WrapperCalendar>
                                        <FullCalendarStyle
                                            plugins={[
                                                dayGridPlugin,
                                                interactionPlugin,
                                                timeGridPlugin,
                                            ]}
                                            buttonText={{
                                                month: "Mois",
                                                week: "Semaine",
                                                today: "Aujourd'hui",
                                            }}
                                            headerToolbar={{
                                                left: "today,prev,next",
                                                center: "title",
                                                right: "",
                                            }}
                                            locale={frLocale}
                                            editable={false}
                                            selectable
                                            aspectRatio={3}
                                            dayMaxEvents
                                            initialView="dayGridMonth"
                                            eventContent={
                                                event_content_function
                                            }
                                            eventClick={(e) =>
                                                showDialog(e, events_calendar)
                                            }
                                            events={events_calendar}
                                            ref={calendarRef}
                                            firstDay={1}
                                            nowIndicator={true}
                                            dayCellContent={
                                                renderDayCellContent
                                            }
                                            datesSet={(arg) =>
                                                DisplayTeamDataIntoManagerCalendar(
                                                    arg
                                                )
                                            }
                                        />
                                    </WrapperCalendar>
                                    <Flex hAlign="center" vAlign="center" fill>
                                        <ButtonSeeSolde__Mobile
                                            onClick={() =>
                                                setShowSoldeDetail(
                                                    !showSoldeDetail
                                                )
                                            }
                                            icon={<CalendarIcon outline />}
                                            primary
                                            content="Voir les soldes en détail"
                                        />
                                    </Flex>
                                </Flex>
                            )}
                            {activeTab === 1 && (
                                <Timeline isOnManagerPage={true} />
                            )}
                            {activeTab === 2 && <LeaveRequestList />}
                        </FlexStyle>
                    </BoxStyle>
                </Container>
            )}
        </ContainerFluid>
    );
};

export default ManagerAbsence;

const ButtonSeeSolde = styled(Button)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: flex;
    }
`;
const ButtonSeeSolde__Mobile = styled(ButtonSeeSolde)`
    width: 100%;
    margin-bottom: 15px;
    @media ${DEVICE.mobileS} {
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;
const FlexStyle = styled(Flex)`
    margin-top: 24px;
    background-color: white;
    box-shadow: 0px 0.15px 0.45px rgba(0, 0, 0, 0.11),
        0px 0.8px 1.8px rgba(0, 0, 0, 0.13);
    @media ${DEVICE.mobileS} {
        padding: 2px;
        padding-top: 15px;
    }
    @media ${DEVICE.laptop} {
        padding: 15px;
    }
`;
const WrapperCalendar = styled.div`
    td {
        cursor: pointer;
    }
    .fc-daygrid-day-frame:has(.holiday) {
        background-color: #fbf6d9 !important;
    }
    margin-top: 24px;
    border-top: 4px solid #9299f7;
    padding: 15px;
    box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13),
        0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const FullCalendarStyle = styled(FullCalendar)`
    padding-block: 24px;
`;
