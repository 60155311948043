import styled, { css } from "styled-components/macro";
import {
    Attachment,
    Dialog,
    Flex,
    MenuButton,
    Text,
} from "@fluentui/react-northstar";
import { DEVICE } from "../../constants/responsive";

export const EmptyTaskDescription = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #242424;
    text-align: center;
`;

export const EmptyTasksTitle = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;

    color: #000000;
`;

export const FlexContainerFluid = styled(Flex)`
    background-color: white;
    position: sticky;
    top: 0px;
    z-index: 5;
    @media ${DEVICE.mobileS} {
        overflow-x: scroll;
    }
    @media ${DEVICE.tablet} {
        overflow-x: unset;
    }
    @media ${DEVICE.laptop} {
        overflow-x: unset;
    }
`;

export const FlexContainerLarge = styled(Flex)`
    background-color: white;
`;

export const FlexContainer = styled(Flex)`
    padding: 0px 28px;
    padding-top: 12px;
    max-width: 1440px;
    margin: 0 auto;
`;

export const DialogStyle = styled(Dialog)`
    @media ${DEVICE.mobileS} {
        padding: 28px 32px !important;
        width: 90% !important;

        top: 32px;
        height: fit-content;
        max-height: calc(100dvh - 80px);
        .ui-buttons {
            display: flex;
            div {
                justify-content: center;
                flex-direction: column-reverse;
                gap: 8px;
            }
            button {
                display: flex;
                max-width: 100%;
                &:first-child {
                    margin: 0px;
                }
            }
        }
    }
    @media ${DEVICE.tablet} {
        padding: 28px 32px !important;
        width: 700px !important;
        .ui-buttons {
            display: flex;
            div {
                justify-content: flex-end;
                flex-direction: row;
                gap: 8px;
            }
            button {
                display: flex;
                max-width: 100%;
                &:first-child {
                    margin: 0px;
                }
            }
        }
    }
`;

export const TableLabel = styled(Text)`
    color: #5c5b5b;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px; /* 258.333% */

    ${({ bolder }) =>
        bolder
            ? css`
                  color: #424242;

                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 20px; /* 142.857% */
              `
            : ""}
`;

export const RowLabel = styled(Text)`
    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;

export const AvatarName = styled(Text)`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const TitleMyTask = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: block;

    margin: 24px;
    margin-inline: 30px;
`;
export const WrapperMytask = styled(Flex)`
    margin: 0 auto;
    @media ${DEVICE.mobileS} {
        width: 100%;
        overflow-x: scroll;
        ${TitleMyTask} {
            position: sticky;
            left: 30px;
        }
    }
    @media ${DEVICE.laptop} {
        width: 100%;
        max-width: 1440px;
        overflow-x: auto;
    }
`;
export const AttachmentStyle = styled(Attachment)`
    max-width: 100%;
    @media ${DEVICE.mobileS} {
        .ui-attachment__header {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100px;
            overflow: hidden;
        }
    }
    @media ${DEVICE.mobileL} {
        .ui-attachment__header {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 200px;
            overflow: hidden;
        }
    }
    @media ${DEVICE.tablet} {
        .ui-attachment__header {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 80%;
            overflow: hidden;
        }
    }
    @media ${DEVICE.laptop} {
        .ui-attachment__header {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
        }
    }
`;

export const FlexSubTab = styled(Flex)`
    padding-inline: 28px;
    max-width: 1440px;
    margin: 0 auto;
    overflow-x: scroll;

    ::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
`;

export const WrapperTasksToValidate = styled(Flex)`
    flex-wrap: wrap;
    gap: 24px;
    padding-bottom: 50px;
    width: 100%;
    @media ${DEVICE.mobileS} {
        justify-content: center;
    }
    @media ${DEVICE.tablet} {
        justify-content: flex-start;
    }
    @media ${DEVICE.laptop} {
        justify-content: flex-start;
    }
`;
export const MenuButtonStyle = styled(MenuButton)`
    position: fixed;
    right: 5px;
    @media ${DEVICE.mobileS} {
        display: inline-flex;
    }
    @media ${DEVICE.mobileL} {
        display: inline-flex;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
`;
