import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";

export const Wrapper = styled(Flex)`
    padding: 8px 12px;
    gap: 16px;
    height: fit-content;
    width: fit-content;
    border-radius: 16px;
    background: ${({ is_holiday }) => (is_holiday ? "#FBF6D9" : "#f0f0f0")};
`;
export const PillContent = styled(Text)`
    color: var(
        --light-theme-rest-foreground-default-foreground-242424,
        #242424
    );
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;
