import React, { useEffect } from "react";
import styled from "styled-components";
import {
    Alert,
    Attachment,
    Button,
    Checkbox,
    Divider,
    Dropdown,
    ExclamationTriangleIcon,
    FilesPdfIcon,
    FilesUploadIcon,
    Flex,
    FlexItem,
    InfoIcon,
    Input,
    InputLabel,
    Loader,
    RadioGroup,
    Text,
    TextArea,
    Tooltip,
    TrashCanIcon,
} from "@fluentui/react-northstar";
import { DEVICE } from "../../../constants/responsive";
import { Controller, useFormContext } from "react-hook-form";

import { ErrorMessage } from "../../../common/styles";
import { useQuery } from "react-query";
import {
    getTaskCategory,
    getTaskResponsable,
    getTaskResponsables,
    getTaskValidators,
} from "../../../api/tasks";
import {
    BASE_URL_ILLUSTRATIONS,
    I18N_NS_TRANSLATION,
} from "../../../constants";
import { useState } from "react";
import { TASK_ACTIONS } from "../../../constants/tasks";
import { file_size_format } from "../../../constants/maths";
import { uploadedFile } from "../../../utils/global";
import { useRef } from "react";
import SliderTabs from "../../../components/shared/SliderTabs";
import { useTranslation } from "react-i18next";
import { DIALOG_ADD_TASK_RULES } from "../../../features/addEmployee/constants/validators";
import { RequiredStar, TitleGroup } from "./style";

const DialogAddIndividualTaskContent = () => {
    const { t } = useTranslation(I18N_NS_TRANSLATION);
    const refButtonUpload = useRef(null);
    const {
        register,
        formState: { errors },
        control,
        setValue,
        clearErrors,
        getValues,
        watch,
    } = useFormContext(); // retrieve all hook methods

    const [taskRequiredValidation, setTaskRequiredValidation] = useState(false);
    const [sliderTab, setSliderTab] = useState(0);
    const [refresh, setRefresh] = useState(null);

    const who_validate = watch("who_validate");
    const task_to_validate = watch("task_to_validate");
    const assigned_to = watch("assigned_to");

    // get all categories
    const { data: dataCategory = [], isLoading: isLoadingCategory } = useQuery(
        ["getCategory"],
        () => getTaskCategory(),
        {
            refetchOnWindowFocus: false,
        }
    );

    // get all collaborateur users.
    let { data: dataCollaborator = [], isLoading: isLoadingCollaborator } =
        useQuery(["getCollaboratorAddTask"], () => getTaskResponsables(), {
            refetchOnWindowFocus: false,
        });
    // get all validators users.
    let {
        data: dataValidators = [],
        isLoading: isLoadingValidators,
        isFetching: isFetchingValidators,
    } = useQuery(
        ["getValidatorsAddTask", assigned_to],
        ({ queryKey }) => {
            return getTaskValidators({ id_user: queryKey.at(1)?.id });
        },
        {
            refetchOnWindowFocus: false,
            enabled: assigned_to !== undefined,
        }
    );

    // if (isLoadingCategory || isLoadingCollaborator || isLoadingValidators)
    //     return <p>Loading...</p>;

    dataCollaborator = dataCollaborator.map((el) => {
        return {
            // header: el.username,
            id: el?.id,
            header: el?.name,
            image: el?.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
            content: el?.job_title,
            username: el?.username,
        };
    });

    if (assigned_to) {
        dataValidators = dataValidators?.map((el) => {
            return {
                // header: el.username,
                id: el?.id,
                header: el?.name,
                image:
                    el?.avatar || `${BASE_URL_ILLUSTRATIONS}/Avatar-defaut.svg`,
                content: el?.job_title,
                username: el?.username,
            };
        });
    } else {
        dataValidators = [];
    }

    return (
        <Flex column gap="gap.medium">
            <FlexItemSlider>
                <SliderTabs
                    tabs={["onboarding", "suivi", "offboarding"]}
                    onChange={(value) => {
                        setSliderTab(value);
                        setValue("type", parseInt(value + 1));
                    }}
                    defaultActiveIndex={sliderTab}
                />
            </FlexItemSlider>
            <Flex.Item>
                <Text>
                    Pour ajouter une tâche, remplissez les informations
                    suivantes :
                </Text>
            </Flex.Item>
            <Flex>
                <TitleGroup content={`1 - Informations sur la tâche`} />
                <RequiredStar />
            </Flex>
            <Flex fill column>
                <Input
                    {...register("name", DIALOG_ADD_TASK_RULES.title)}
                    label="Titre de la tâche"
                    placeholder="Donnez un titre évocateur"
                    error={errors?.name ? true : false}
                    styles={{ marginBottom: "2px" }}
                    fluid
                />
                {errors?.name && (
                    <ErrorMessage content={t(errors?.name?.message)} />
                )}
            </Flex>
            <Flex fill vAlign="center" styles={{ alignSelf: "center" }} column>
                <Label>Catégorie de la tâche</Label>
                <Controller
                    control={control}
                    rules={DIALOG_ADD_TASK_RULES.category}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            items={dataCategory?.map((el) => el?.name)}
                            placeholder="Catégorie de la tâche"
                            checkable
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onBlur={onBlur} // notify when input is touched
                            onChange={(_, option) =>
                                onChange(
                                    dataCategory[option.highlightedIndex].id
                                )
                            } // send value to hook form
                            checked={value}
                            error={errors?.category ? true : false}
                            ref={ref}
                        />
                    )}
                    name="category"
                />
                {errors?.category && (
                    <ErrorMessage content={t(errors?.category.message)} />
                )}
            </Flex>
            <Flex gap="gap.large" vAlign="end">
                <Flex.Item>
                    <Flex column fill>
                        <Input.Label>
                            Consignes pour accomplir la tâche
                        </Input.Label>
                        <TextArea
                            label="Consignes pour accomplir la tâche"
                            placeholder="Ajouter des indications ici"
                            fluid
                            {...register(
                                "description",
                                DIALOG_ADD_TASK_RULES.description
                            )}
                            error={errors?.description ? true : false}
                        />
                        {errors?.description && (
                            <ErrorMessage
                                content={t(errors?.description.message)}
                            />
                        )}
                    </Flex>
                </Flex.Item>
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup
                    content={`2 - Que doit faire le collaborateur sur un document ? (facultatif)`}
                />
            </Flex>
            <Flex column gap="gap.small">
                <Input.Label>Action demandée au collaborateur</Input.Label>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <DropdownStyle
                            items={TASK_ACTIONS}
                            placeholder="Aucune action demandée"
                            defaultValue={TASK_ACTIONS[4].header}
                            getA11ySelectionMessage={{
                                onAdd: (item) => `${item} has been selected.`,
                            }}
                            onChange={(_, i) => {
                                const {
                                    document_required,
                                    id: document_action,
                                } = i?.value;
                                setValue(
                                    "document_required",
                                    document_required
                                );
                                setValue("document_action", document_action);
                                clearErrors("document_action");
                                // onChange(document_action);
                                setRefresh(new Date().getTime());
                                return;
                            }}
                            ref={ref}
                            onBlur={onBlur}
                            error={errors?.document_action ? true : false}
                            checkable
                        />
                    )}
                    name="document_required"
                />
                {errors?.document_action && (
                    <ErrorMessage
                        content={t(errors?.document_action.message)}
                    />
                )}
                {getValues("document_required") &&
                    getValues("saved_files").length === 0 && (
                        <div>
                            <Button
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onClick={() => refButtonUpload.current.click()}
                                tinted
                            />
                            <Input
                                hidden
                                ref={refButtonUpload}
                                type="file"
                                icon={<FilesUploadIcon size="small" />}
                                content="Ajouter la pièce-jointe"
                                iconPosition="before"
                                onChange={(e) =>
                                    uploadedFile(e.target.files, (args) => {
                                        setValue("saved_files", args);
                                        clearErrors("document_action");
                                        setRefresh(new Date().getTime());
                                        return;
                                    })
                                }
                            />
                        </div>
                    )}
                {/* view the document */}
                {getValues("saved_files")?.map((el) => {
                    return (
                        <Attachment
                            icon={<FilesPdfIcon />}
                            header={el.filename || el.name}
                            description={file_size_format(el.filesize)}
                            actionable
                            action={{
                                icon: <TrashCanIcon />,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setValue("saved_files", []);
                                    setRefresh(new Date().getTime());
                                },
                                title: "Remove",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={{ maxWidth: "100%" }}
                        />
                    );
                })}
            </Flex>
            <Divider />
            <Flex>
                <TitleGroup content={`3 - Date plannifiée`} />
                <RequiredStar />
            </Flex>
            <FlexDates>
                <Flex column>
                    <Input
                        {...register(
                            "due_date",
                            DIALOG_ADD_TASK_RULES.due_date
                        )}
                        type="date"
                        label="Date d'échéance"
                        placeholder="due_date"
                        error={errors?.due_date ? true : false}
                        min={new Date().toJSON().split("T")[0]}
                    />
                    {errors?.due_date && (
                        <ErrorMessage content={t(errors?.due_date.message)} />
                    )}
                </Flex>
            </FlexDates>
            <Divider />
            <Flex>
                <TitleGroup content={`4 - Personnes concernées`} />
                <RequiredStar />
            </Flex>
            <FlexConcerne vAlign="start" column>
                <Flex column>
                    <Flex>
                        <Input.Label>Qui va effectuer la tâche ?</Input.Label>
                    </Flex>
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.assigned_to}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                search
                                items={dataCollaborator}
                                placeholder="Qui va effectuer la tâche ?"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onChange={(_, option) => {
                                    onChange(option.value);
                                }}
                                onBlur={onBlur}
                                name={name}
                                error={errors?.assigned_to ? true : false}
                                styles={{
                                    width: "100%",
                                }}
                            />
                        )}
                        name="assigned_to"
                    />
                    {errors?.assigned_to && (
                        <ErrorMessage
                            content={t(errors?.assigned_to.message)}
                        />
                    )}
                </Flex>
                <Flex column>
                    <Flex>
                        <Input.Label>
                            À qui cette tâche va profiter ?{" "}
                            <Tooltip
                                content="Lorem ipsum"
                                trigger={
                                    <InfoIcon
                                        outline
                                        size="small"
                                        styles={{
                                            color: "var(--color-primary)",
                                        }}
                                    />
                                }
                            />
                        </Input.Label>
                    </Flex>
                    <Controller
                        control={control}
                        rules={DIALOG_ADD_TASK_RULES.related_to}
                        render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState,
                        }) => (
                            <DropdownStyle
                                search
                                items={dataCollaborator}
                                placeholder="À qui cette tâche va profiter ?"
                                checkable
                                getA11ySelectionMessage={{
                                    onAdd: (item) =>
                                        `${item} has been selected.`,
                                }}
                                onChange={(_, option) => {
                                    onChange(
                                        option && option.value
                                            ? option.value.username
                                            : ""
                                    );
                                }}
                                onBlur={onBlur}
                                name={name}
                                ref={ref}
                                error={errors?.related_to ? true : false}
                            />
                        )}
                        name="related_to"
                    />
                    {errors?.related_to && (
                        <ErrorMessage content={t(errors?.related_to.message)} />
                    )}
                </Flex>
            </FlexConcerne>
            <Flex>
                <Controller
                    control={control}
                    render={({
                        field: { onChange, onBlur, value, name, ref },
                        fieldState: { invalid, isTouched, isDirty, error },
                        formState,
                    }) => (
                        <Checkbox
                            label="Tâche à faire valider"
                            onChange={(e, { checked }) => {
                                setTaskRequiredValidation(checked);
                                onChange(checked);
                            }}
                            toggle
                            onBlur={onBlur}
                            name={name}
                            ref={ref}
                        />
                    )}
                    name="task_to_validate"
                />
            </Flex>
            {taskRequiredValidation && (
                <Flex column>
                    <InputLabel content="Qui va valider la réalisation de la tâche ?" />
                    {isLoadingValidators || isFetchingValidators ? (
                        <Flex>
                            <Loader size="small" />
                        </Flex>
                    ) : (
                        <Controller
                            rules={DIALOG_ADD_TASK_RULES.who_validate}
                            control={control}
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: {
                                    invalid,
                                    isTouched,
                                    isDirty,
                                    error,
                                },
                                formState,
                            }) => (
                                <DropdownStyle
                                    search
                                    items={dataValidators}
                                    placeholder="Qui va valider la réalisation de la tâche ?"
                                    getA11ySelectionMessage={{
                                        onAdd: (item) =>
                                            `${item} has been selected.`,
                                    }}
                                    onChange={(_, i) =>
                                        onChange(
                                            i && i.value
                                                ? i.value.username
                                                : " "
                                        )
                                    }
                                    onBlur={onBlur}
                                    name={name}
                                    ref={ref}
                                    checkable
                                    error={errors?.who_validate ? true : false}
                                />
                            )}
                            name="who_validate"
                        />
                    )}
                    {errors?.who_validate && (
                        <ErrorMessage
                            content={t(errors?.who_validate.message)}
                        />
                    )}
                </Flex>
            )}
        </Flex>
    );
};

export default DialogAddIndividualTaskContent;

const Label = styled(Text)`
    -webkit-text-size-adjust: 100%;
    font-size: 0.875rem;
    text-align: left;
    color: rgb(17, 16, 15);
    unicode-bidi: isolate;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;

    display: block;
    transition: all 0.2s ease 0s;
    line-height: 1rem;
    margin-bottom: 0.25rem;
`;

export const DropdownStyle = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        width: 100%;
        max-width: 100%;
        .ui-dropdown__container {
            width: 100%;
        }
    }
    @media ${DEVICE.tablet} {
        width: auto;
        max-width: 300px;
    }
    @media ${DEVICE.laptop} {
        width: 280px;
        max-width: auto;
        img {
            width: 34px;
            height: 34px;
        }
    }
`;

const FlexConcerne = styled(Flex)`
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexDates = styled(Flex)`
    gap: 16px;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const FlexItemSlider = styled(FlexItem)`
    @media ${DEVICE.mobileS} {
        margin-top: 16px;
    }
    @media ${DEVICE.tablet} {
        margin-top: 0px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 0px;
    }
`;

const FlexDescription = styled(Flex)`
    gap: 16px;
    align-items: flex-start;
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
    }
`;

const TextTitle = styled(Text)`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #616161;
`;
