import { Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components/macro";
import { COLUMN_LEFT_WIDTH } from "../constants";
import { motion } from "framer-motion";
import { DEVICE } from "../../../constants/responsive";

export const TypeLeaveText = styled(Text)`
    color: #242424;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
`;

export const TextMonth = styled(Text)`
    color: #242424;

    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    @media ${DEVICE.mobileS} {
        font-size: 12px;
    }
    @media ${DEVICE.laptop} {
        font-size: 24.5px;
    }
`;

export const UserName = styled(Text)`
    color: #242424;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media ${DEVICE.mobileS} {
        display: block;
        white-space: nowrap;
        width: 70px;
    }
    @media ${DEVICE.laptop} {
        width: 150px;
        display: block;
    }
`;

export const AccordionTitleText = styled(Text)`
    color: #424242;

    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
    @media ${DEVICE.mobileS} {
        font-size: 10px;
        font-weight: 400;
    }
    @media ${DEVICE.laptop} {
        font-size: 14px;
        font-weight: 700;
    }
`;

export const User = styled(Flex)`
    height: 35px;
    :hover {
        outline: 1px solid #d1d1d1;
        background: #e8ebfa;
        ${UserName} {
            color: #5b5fc7;
            text-align: start;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            text-decoration-line: underline;
            cursor: pointer;
            /* border: 1px solid orange; */
        }
    }
    @media ${DEVICE.mobileS} {
        padding-left: 8px;
        padding-right: 8px;
    }
    @media ${DEVICE.tablet} {
        padding-left: 10px;
        padding-right: 8px;
    }
    @media ${DEVICE.laptop} {
        padding-left: 40px;
        padding-right: 8px;
    }
`;

export const WrapperUsers = styled(Flex)``;

export const AccordionTitle = styled(Flex)``;

export const Accordion = styled(Flex)`
    @media ${DEVICE.mobileS} {
        width: 116px;
        padding-left: 3px;
        padding-right: 4px;
    }
    @media ${DEVICE.laptop} {
        padding-left: 16px;
        padding-right: 4px;
        width: 100%;
    }
`;

export const WeeksHeader = styled(Flex)`
    min-height: 35px;
`;

export const CellTitle = styled(Text)`
    color: #242424;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
`;

export const LeftColumnCell = styled(Flex)`
    height: 35px;
    flex-shrink: 0;
    background-color: #fafafa;

    @media ${DEVICE.mobileS} {
        min-width: 116px;
    }
    @media ${DEVICE.laptop} {
        min-width: 230px;
    }
`;

export const CalendarTimeline = styled(Flex)`
    margin: 24px 16px;
    padding-bottom: 15px;
    width: 1335px;
    max-height: 866px;
    overflow-x: scroll;
    overflow-y: scroll;

    background: white;
`;

export const Wrapper = styled(Flex)`
    background-color: ${({ color }) => (color ? color : "lightgrey")};
    position: absolute;
    height: 18px;
    left: ${({ startPx }) => startPx + "px"};
    width: ${({ width }) => width + "px"};
    border-radius: 3px;
    cursor: pointer;
    outline: 1px solid transparent;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Column = styled(Flex)`
    width: ${({ width }) => (width ? width + "px" : "68px")};
    outline: ${({ noBorder }) => (noBorder ? "none" : "1px solid lightgrey")};
    background-color: ${({ isToday, isWeekend }) =>
        isWeekend ? "#EBEBEB" : isToday ? "#4F52B2" : "white"};
    height: 35px;
    color: ${({ isToday }) => (isToday ? "white" : "black")};
    font-weight: ${({ isToday }) => (isToday ? "bold" : "light")};
`;

export const Row = styled(motion.div)`
    display: flex;
    height: 35px;
    position: relative;
    :hover {
        ${Column} {
            background: ${({ nobackground }) =>
                nobackground ? "auto" : "#ededed"};
        }
    }
`;

export const LeftColumn = styled(Flex)`
    background-color: white;
    z-index: 2;
    position: sticky;
    left: 0;
    @media ${DEVICE.mobileS} {
        min-width: 116px;
    }
    @media ${DEVICE.laptop} {
        min-width: 231px;
    }
`;

export const Event = styled(Text)`
    background-color: inherit;
    color: black;
    border-radius: 3px;
    height: 18px;
    padding-left: 5px;
    position: sticky;
    left: ${COLUMN_LEFT_WIDTH + "px"};

    cursor: pointer;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
`;
