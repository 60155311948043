import { BASE_URL_API } from "../../constants";
import fetcher from "../../utils/fetcher";

export const getGroupOffice = async () => {
    return fetcher(BASE_URL_API + "/office_group/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getRoles = async () => {
    return fetcher(BASE_URL_API + "/roles/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getResources = async () => {
    return fetcher(BASE_URL_API + "/resources/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getAgencies = async () => {
    return fetcher(BASE_URL_API + "/agencies/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getContractType = async () => {
    return fetcher(BASE_URL_API + "/contracts_types/list", {
        method: "GET",
        credentials: "include",
    });
};

export const getPolicies = async ({ agency_id }) => {
    console.log({ agency_id });
    return fetcher(`${BASE_URL_API}/agency/${agency_id}/leave_policies`, {
        method: "GET",
        credentials: "include",
    });
};

export const getTypeContract = async () => {
    return fetcher(BASE_URL_API + "/contracts_types/list", {
        method: "GET",
        credentials: "include",
    });
};

export const _helper_api_get_list_hr = async () => {
    return fetcher(BASE_URL_API + "/hr/list", {
        method: "GET",
        credentials: "include",
    });
};
