import moment from "moment";
import { BASE_URL_API } from "../../../constants";
import fetcher from "../../../utils/fetcher";
import { useAuthStore } from "../../../stores/useAuthStore";
/**
 * @typedef {Object} PropType
 * @property {string} date - date format YYYY-MM-DD
 */
/**
 * API call for getting time reporting data of the user
 * @param {PropType} PropType
 * @returns
 */
export const _api_get_time_reporting = ({ date }) => {
    return fetcher(`${BASE_URL_API}/time_reporting/week/${date}`, {
        method: "GET",
        credentials: "include",
    });
};
/**
 * @typedef {Object} PropTypes
 * @property {number} day_id
 * @property {{ id : number, start_hour : string, end_hour : string }[]} data - format hour 00:00:00
 */
/**
 * API call for getting time reporting data of the user
 * @param {PropTypes} PropTypes
 * @returns
 */
export const _api_update_day_slot = ({ day_id, data }) => {
    return fetcher(`${BASE_URL_API}/time_reporting/day/${day_id}/edit`, {
        method: "PUT",
        body: JSON.stringify(data),
        credentials: "include",
    });
};

export const _api_get_detail_dialog = ({ user_id, year, month_index }) => {
    return fetcher(
        `${BASE_URL_API}/time_reporting/user/${user_id}/year/${year}/month/${month_index}/details`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};

export const _api_post_tips = () => {
    return fetcher(`${BASE_URL_API}/time_reporting/user/tips`, {
        method: "POST",
        credentials: "include",
    });
};

export const _api_save_week = ({ week }) => {
    return fetcher(`${BASE_URL_API}/time_reporting/week/${week}/save`, {
        method: "PUT",
        credentials: "include",
    });
};

export const _api_send_month = ({ month }) => {
    return fetcher(`${BASE_URL_API}/time_reporting/month/${month}/save`, {
        method: "POST",
        credentials: "include",
    });
};

export const _api_apply_week_type = ({ week }) => {
    return fetcher(
        `${BASE_URL_API}/time_reporting/week/${week}/apply_week_template`,
        {
            method: "POST",
            credentials: "include",
        }
    );
};

export const _api_get_template_week = () => {
    return fetcher(`${BASE_URL_API}/time_reporting/week_template/details`, {
        method: "GET",
        credentials: "include",
    });
};
export const _api_get_monthly_tracking = ({ year, month_index }) => {
    return fetcher(
        `${BASE_URL_API}/time_reporting/year/${year}/month/${month_index}/details`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const _api_save_template_week = (data) => {
    return fetcher(`${BASE_URL_API}/time_reporting/week_template/save`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
    });
};
export const _api_format_slot = ({ slots, day_id }) => {
    return fetcher(
        `${BASE_URL_API}/time_reporting/week_template/format_slots`,
        {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                slots: slots?.map((slot) => ({
                    day_id,
                    start_time: slot?.start_time,
                    end_time: slot?.end_time,
                })),
            }),
        }
    );
};

export const _api_get_annual_recovery = (year) => {
    return fetcher(`${BASE_URL_API}/time_reporting/year/${year}/details`, {
        method: "GET",
        credentials: "include",
    });
};

export const _api_get_all_data_for_my_team = (currentMonth) => {
    const year = currentMonth.year;
    const monthIndex = currentMonth.index + 1;

    return fetcher(
        `${BASE_URL_API}/time_reporting/manager/year/${year}/month/${monthIndex}/teams/details`,
        {
            method: "GET",
            credentials: "include",
        }
    );
};
export const _api_get_weekly_tracked_data = ({ date }) => {
    return fetcher(`${BASE_URL_API}/time_reporting/manager/week/${date}`, {
        method: "GET",
        credentials: "include",
    });
};
export const _api_get_counter_badge = () => {
    return fetcher(`${BASE_URL_API}/time_reporting/manager/months_to_approve`, {
        method: "GET",
        credentials: "include",
    });
};

/**
 * @typedef {Object} PropTypesValidation
 * @param {Array<number>} users // ids des utilisateurs à modifier
 * @param {"approve"|"refuse"|"remind"} type
 * @param {{ index: number, year : number }} currentMonth
 */
/**
 * API call for approve or reject or remind time reporting
 * @param {PropTypesValidation} PropTypes
 * @returns
 */
export const _api_post_validate_time_reporting = ({
    users,
    type,
    currentMonth,
}) => {
    const month = currentMonth.index + 1;
    const year = currentMonth.year;

    return fetcher(
        `${BASE_URL_API}/time_reporting/manager/year/${year}/month/${month}/teams/${type}`,
        {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({
                user_ids: users,
            }),
        }
    );
};

export const _api_export_time_reporting_selected_user = async (data) => {
    const user = useAuthStore.getState().infos;
    const year = data?.currentMonth?.year;
    const month = data?.currentMonth?.index + 1;
    const path = data?.path;
    const onDownloadSuccess = data?.onDownloadSuccess;

    return await fetch(
        `${BASE_URL_API}/time_reporting/manager/year/${year}/month/${month}/teams/export?${path}`,
        {
            headers: {
                Authorization: `Bearer ${user?.access}`,
            },
            method: "GET",
            credentials: "include",
        }
    )
        .then(async (res) => {
            const contentDisposition = res.headers.get("Content-Disposition");
            // console.log("Content-Disposition header:", contentDisposition);

            // Extract filename from Content-Disposition header
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            let filename = "user_format_export.xlsx";
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, "");
            }

            const blob = await res.blob();
            return { blob, filename };
        })
        .then(({ blob, filename }) => {
            onDownloadSuccess();
            let a = document.createElement("a");
            if (window.URL && window.URL.createObjectURL) {
                let objectUrl = window.URL.createObjectURL(blob);
                a.href = objectUrl;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(objectUrl);
            } else {
                // Fallback for browsers that do not support createObjectURL
                // You may need to handle this case differently based on your requirements
                alert(
                    "Your browser does not support this feature. Please try another browser."
                );
            }
        })
        .catch((error) => {
            console.error("Error fetching or processing data:", error);
            // Handle error as needed
        });
};
