import React from "react";
import moment from "moment";
import { Flex, Text } from "@fluentui/react-northstar";
import { Clock16Regular } from "@fluentui/react-icons";

import { Column, Event, Row, TypeLeaveText, Wrapper } from "../../styles";
import { COLUMN_WIDTH } from "../../constants";
import DialogLeaveDetail from "../Dialogs/DialogLeaveDetail";
import { useQueryClient } from "react-query";

const calculateDimensions = ({ row, calendar, column_length }) => {
    const event_month = moment(row.start_date).get("month") + 1;

    const start_day =
        event_month !== calendar.month + 1
            ? 0
            : moment(row.start_date).get("date") - 1;

    const end_day = moment(row.end_date).get("date");
    const startPx = start_day * COLUMN_WIDTH;
    const width = COLUMN_WIDTH * (end_day - start_day);
    const max_width = (column_length - start_day) * COLUMN_WIDTH;

    return { startPx, width, max_width };
};

const RowsFillTimelineWithData = ({
    rowsData,
    column_length,
    weekends,
    calendar,
    setConfigToast,
}) => {
    const clientQuery = useQueryClient();

    const successRequest = ({ toastMessage = "", level = "success" }) => {
        // show toast.
        setConfigToast({
            visible: true,
            content: toastMessage,
            level,
        });
        return clientQuery.refetchQueries(["timeline::get"]);
    };

    return rowsData.map((rows) => {
        if (rows.rowsCounter === null) return <Row></Row>;
        if (rows.collapsed === true)
            return (
                <Row
                    initial={{
                        height: 35,
                    }}
                    animate={{
                        height: 0,
                    }}
                    transition={{
                        duration: 0.2,
                    }}
                ></Row>
            );
        return rows.events.map((row, i) => {
            return (
                <Row
                    initial={{
                        height: 0,
                    }}
                    animate={{
                        height: "auto",
                    }}
                    transition={{
                        duration: 0.2,
                    }}
                >
                    {new Array(column_length).fill("").map((e, i) => (
                        <Column isWeekend={weekends.includes(i + 1)}></Column>
                    ))}
                    {
                        <Flex vAlign="center">
                            {row.map((data) => {
                                const { startPx, max_width, width } =
                                    calculateDimensions({
                                        row: data,
                                        calendar,
                                        column_length,
                                    });
                                return (
                                    <DialogLeaveDetail
                                        leave_details={data}
                                        user_details={data.user}
                                        content={
                                            <Wrapper
                                                startPx={startPx}
                                                width={
                                                    width <= 0
                                                        ? max_width
                                                        : width
                                                }
                                                color={data.type.color}
                                            >
                                                <Event
                                                    truncated
                                                    content={
                                                        <Flex
                                                            vAlign="center"
                                                            styles={{
                                                                border: "1px solid transparent",
                                                                width:
                                                                    width <= 0
                                                                        ? max_width +
                                                                          "px"
                                                                        : width +
                                                                          "px",
                                                            }}
                                                        >
                                                            {data.status.id ===
                                                                1 && (
                                                                <Clock16Regular />
                                                            )}
                                                            <TypeLeaveText
                                                                truncated
                                                                content={
                                                                    data.type
                                                                        .name
                                                                }
                                                            />
                                                        </Flex>
                                                    }
                                                />
                                            </Wrapper>
                                        }
                                        cbOnApproveLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été acceptée"
                                                    : data?.message,
                                            });
                                        }}
                                        cbOnRefuseLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été refusée"
                                                    : data?.message,
                                            });
                                        }}
                                        cbOnCancelLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été Annulée"
                                                    : data?.message,
                                            });
                                        }}
                                        cbOnDeleteLeave={(data) => {
                                            const isSuccess = data?.success;
                                            successRequest({
                                                level: isSuccess
                                                    ? "success"
                                                    : "danger",
                                                toastMessage: isSuccess
                                                    ? "La demande a bien été Supprimée"
                                                    : data?.message,
                                            });
                                        }}
                                    />
                                );
                            })}
                        </Flex>
                    }
                </Row>
            );
        });
    });
};

export default RowsFillTimelineWithData;
