import React from "react";
import { ContentText, Wrapper } from "./index.style";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { useTranslation } from "react-i18next";

const DayType = ({ is_not_working, is_weekly_rest = false }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    return (
        <Wrapper fill vAlign="center" hAlign="center">
            {is_not_working && (
                <ContentText content={t("v2.home.type_days.not_working")} />
            )}
            {is_weekly_rest && (
                <ContentText content={t("v2.home.type_days.weekly_leave")} />
            )}
        </Wrapper>
    );
};

export default DayType;
