import { BASE_URL_API } from "../../../constants";

export const updatePassword = ({ token, password, password_confirmation }) => {
    return fetch(BASE_URL_API + "/onboarding/user/activate", {
        method: "PUT",
        body: JSON.stringify({
            token,
            password,
            password_confirmation,
        }), // The data
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
        });
};

export const checkActivationCode = (token) => {
    return fetch(BASE_URL_API + "/onboarding/user/check_activation_code", {
        method: "POST",
        body: JSON.stringify({
            token,
        }), // The data
        headers: {
            "Content-type": "application/json", // The type of data you're sending
        },
        credentials: "include",
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.log(error);
        });
};
