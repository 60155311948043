import React from "react";
import {
    BadgeComment,
    BadgeNumber,
    CardBody,
    CardHeader,
    Container,
    Day,
    HoursCounter,
    HoursText,
    Wrapper,
    WrapperHours,
} from "./index.style";
import Pills from "../Pills";
import DayType from "../DayType";
import DaySlots from "../DaySlots";
import DialogModifyWeek from "../DialogModifyWeek";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import CardWeekSkeleton from "../CardWeekSkeleton";
import OverlayEditableDay from "../OverlayEditableDay";
import { useState } from "react";
import {
    VehicleCarProfileRegular,
    CommentRegular,
    HomeRegular,
} from "@fluentui/react-icons";
import { STYLES_EVENTS, TYPE_EVENT } from "../../../remote_job/constants";

/**
 * @typedef {Object} PropType
 * @property {boolean} is_today
 * @property {{value: number}} type_day
 * @property {Array<>} shifts
 * @property {string} day_name - format Lun. 02
 * @property {number} status_day
 * @property {boolean} is_saved_week
 * @property {number} hours_count
 * @property {boolean} mode_editable_activated
 * @property {boolean} is_loading
 * @property {boolean} can_modify_day
 */

/**
 * CardWeek pour afficher les détail d'une journée.
 * @param {PropType} PropType
 * @returns
 */
const CardWeek = ({
    is_today = false,
    type_day,
    day_id,
    shifts,
    day_name,
    status_day,
    is_saved_week,
    hours_count,
    mode_editable_activated,
    can_modify_day,
    data_day,
    day_index,
    onConfirmModifyDay = () => console.log("day modified"),
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const [isHoverBody, setIsHoverBody] = useState(false);
    const [openDefaultModifyDayOverlay, setOpenDefaultModifyDayOverlay] =
        useState(false);

    const isEmptySlot = type_day.value === 0 && shifts.length === 0;
    const isLeave = type_day.value === 2;
    const isNotWorking = type_day.value === 3;
    const isWeeklyRest = type_day.value === 4;
    const isHoliday = type_day.value === 1;
    const isWorkRemote = data_day?.is_telework;
    const isWorkTravel = data_day?.is_travel;

    const isEditable =
        can_modify_day && (!is_saved_week || mode_editable_activated);
    // * !is_saved_week || (mode_editable_activated && can_modify_day);

    const isContainComment = data_day?.comment;

    return (
        <Container
            column
            styles={{ maxWidth: "190px", minWidth: "190px" }}
            id={`card_${day_index}`}
        >
            <CardHeader is_today={is_today} vAlign="center" space="between">
                <Day content={day_name} is_today={is_today} />
                {(openDefaultModifyDayOverlay ||
                    (isEditable && !isLeave && !isHoliday)) && (
                    <DialogModifyWeek
                        day_data={data_day}
                        shifts={shifts}
                        day_id={day_id}
                        onConfirm={() => {
                            setOpenDefaultModifyDayOverlay(false);
                            onConfirmModifyDay();
                        }}
                        onCancel={() => {
                            setOpenDefaultModifyDayOverlay(false);
                        }}
                        openDefault={openDefaultModifyDayOverlay}
                    />
                )}
            </CardHeader>
            <Flex
                column
                styles={{ position: "relative" }}
                fill
                onMouseEnter={() => {
                    if (!isEditable) return;
                    return setIsHoverBody(true);
                }}
                onMouseLeave={() => {
                    if (!isEditable) return;
                    return setIsHoverBody(false);
                }}
            >
                {isEditable && isHoverBody && !isHoliday && !isLeave && (
                    <Wrapper vAlign="center" hAlign="center">
                        <Button
                            content={t("v2.home.overlay.button_update_day")}
                            inverted
                            onClick={() => {
                                return setOpenDefaultModifyDayOverlay(true);
                            }}
                        />
                    </Wrapper>
                    // <OverlayEditableDay
                    //     day_data={data_day}
                    //     shifts={shifts}
                    //     day_id={day_id}
                    //     onConfirm={() => setIsHoverBody(false)}
                    //     onCancel={() => setIsHoverBody(false)}
                    // />
                )}
                <Flex
                    hAlign="center"
                    column
                    styles={{
                        height: "80px",
                        padding: "8px 0px 0px 0px",
                    }}
                >
                    <Flex styles={{ width: "100%", paddingInline: "8px" }}>
                        <Flex
                            hAlign="end"
                            gap="gap.smaller"
                            fill
                            styles={{ width: "100%" }}
                        >
                            <Flex styles={{ height: "16px" }}></Flex>
                            {isContainComment && (
                                <BadgeComment vAlign="center">
                                    <BadgeNumber>1</BadgeNumber>
                                    <CommentRegular fontSize={11} />
                                </BadgeComment>
                            )}
                            {isWorkRemote && (
                                <BadgeComment vAlign="center" hAlign="center">
                                    <HomeRegular fontSize={11} />
                                </BadgeComment>
                            )}
                            {isWorkTravel && (
                                <BadgeComment vAlign="center" hAlign="center">
                                    <VehicleCarProfileRegular
                                        fontSize={11}
                                        color="#252423"
                                    />
                                </BadgeComment>
                            )}
                        </Flex>
                    </Flex>
                    <WrapperHours vAlign="end" hAlign="center">
                        <HoursCounter content={hours_count} />
                        <HoursText content={t("v2.home.labels.hours_count")} />
                    </WrapperHours>
                </Flex>
                <CardBody fill hAlign="center" column>
                    {/* pills empty slots */}
                    {isHoliday && <Pills is_holiday={true} />}
                    {isEmptySlot && <Pills />}
                    {/* repos hebdo, jour non travaillé */}
                    {(isNotWorking || isWeeklyRest) && (
                        <DayType
                            is_not_working={isNotWorking}
                            is_weekly_rest={isWeeklyRest}
                        />
                    )}
                    {/* jour de travail */}
                    {(shifts.length !== 0 || isLeave) && (
                        <DaySlots
                            shifts={shifts}
                            value_day_type={type_day?.value}
                            status_day={status_day}
                            data_day={data_day}
                        />
                    )}
                </CardBody>
            </Flex>
        </Container>
    );
};

export default CardWeek;
