import {
    ChevronDownIcon,
    EditIcon,
    EyeFriendlierIcon,
    Flex,
    Image,
    SendIcon,
    Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import Card from "./Card";
import styled from "styled-components";
import {
    DocumentBulletListClock24Filled,
    CheckmarkStarburst20Filled,
} from "@fluentui/react-icons";
import Toast from "../../../components/Alerts";
import EmptyTodoTask from "../img/EmptyTodoTask.png";
import DialogDetailTask from "../../../pages/DashboardTasks/DialogDetailTask";
import { TASK_ACTIONS, TASK_STATUS } from "../../../constants/tasks";
import { useQueryClient } from "react-query";
const DocumentUploaded = ({
    state,
    setstate,
    status,
    lengthdone,
    done,
    isLoading,
    isFetching,
}) => {
    const [configToast, setConfigToast] = useState({
        visible: false,
        content: "",
        level: "warning",
    });
    const queryClient = useQueryClient();

    return (
        <Flex column>
            <HeaderDone fill vAlign="center" space="between">
                <Toast
                    level={configToast.level}
                    content={configToast.content}
                    visible={configToast.visible}
                    onStatusChange={() =>
                        setConfigToast({
                            visible: false,
                            content: "",
                            level: "warning",
                        })
                    }
                />
                <LeftHeaderDone vAlign="center">
                    <Flex
                        styles={{
                            background: "#EBEBEB",
                            padding: "6px",
                            borderRadius: "4px",
                            color: "#616161",
                        }}
                    >
                        <DocumentBulletListClock24Filled />
                    </Flex>
                    <HeaderTextDone content="Actions terminées" />
                </LeftHeaderDone>
                <RightHeader vAlign="center">
                    {state ? (
                        <ChevronDownIcon
                            onClick={() => setstate(!state)}
                            outline
                            style={{
                                color: "#616161",
                                whidth: "20px",
                                height: "20px",
                                cursor: "pointer",
                            }}
                        />
                    ) : (
                        <ChevronDownIcon
                            onClick={() => setstate(!state)}
                            outline
                            style={{
                                color: "#616161",
                                whidth: "20px",
                                height: "20px",
                                cursor: "pointer",
                            }}
                            rotate={180}
                        />
                    )}
                </RightHeader>
            </HeaderDone>
            {!state ? (
                <ContentDone column>
                    <Flex
                        column
                        hAlign="center"
                        fill
                        style={{
                            padding: "16px",
                            gap: "8px",
                        }}
                    >
                        {lengthdone === 0 ? (
                            <Flex
                                gap="gap.medium"
                                vAlign="center"
                                hAlign="center"
                                fill
                                column
                            >
                                <Image src={EmptyTodoTask} />
                                {/* <Title content="Toutes les actions ont été effectuées" /> */}
                                <Title content="Absence d'actions terminés " />
                                <DescriptionText content="Vous receverez un mail en cas d'autres actions à faire" />
                            </Flex>
                        ) : (
                            done?.map((el) => {
                                return (
                                    <DialogDetailTask
                                        trigger={
                                            <Card
                                                isPrimary={true}
                                                ButtonIcon={null}
                                                action={
                                                    TASK_ACTIONS.find(
                                                        (action) =>
                                                            action.id ===
                                                            el?.document_action
                                                    )?.element
                                                }
                                                actionText={el?.name}
                                                icon={
                                                    <CheckmarkStarburst20Filled
                                                        style={{
                                                            color: "green",
                                                        }}
                                                    />
                                                }
                                                due_date={el?.due_date}
                                                isLoading={isLoading}
                                                isFetching={isFetching}
                                                status={status}
                                            />
                                        }
                                        hideCreationAndValidation={true}
                                        key={el?.id}
                                        task={{
                                            name: el?.name,
                                            category: {
                                                name: el?.category?.name,
                                            },
                                            description: el?.description,
                                            document_required:
                                                el?.document_required,
                                            due_date: el?.due_date,
                                            status: TASK_STATUS.DONE,
                                            document_action:
                                                el?.document_action,
                                            documents: el?.documents,
                                            documents_template:
                                                el?.documents_template,
                                            id: el?.id,
                                            assigned_to_user: {
                                                id: el?.assigned_to_user?.id,
                                            },
                                        }}
                                        onSuccess={({ data, message }) => {
                                            setConfigToast({
                                                visible: true,
                                                content: message,
                                                level: data?.success
                                                    ? "success"
                                                    : "danger",
                                            });
                                            if (data?.success) {
                                                // refetch the data
                                                queryClient.refetchQueries([
                                                    "preonboarding_information",
                                                ]);
                                            }
                                        }}
                                        onError={({ message }) => {
                                            setConfigToast({
                                                visible: true,
                                                content: message,
                                                level: "danger",
                                            });
                                        }}
                                    />
                                );
                            })
                        )}
                        {/*  */}
                        {/* <Card
                            status={status}
                            ButtonIcon={<EyeFriendlierIcon outline />}
                            action={"Consulter"}
                            actionText={"Le formulaire pour la mutuelle"}
                            icon={
                                <CheckmarkStarburst20Filled
                                    style={{ color: "green" }}
                                />
                            }
                        /> */}
                    </Flex>
                </ContentDone>
            ) : null}
        </Flex>
    );
};

export default DocumentUploaded;

const NoTaskImg = styled(Image);

const ContentDone = styled(Flex)`
    overflow-y: scroll;
    height: 541px;

    background-color: #ebebeb;
`;

const HeaderDone = styled(Flex)`
    padding: 0px 16px 0px 32px;
    background: #ebebeb;
    /* width: 442px; */
    height: 60px;
`;

const HeaderTextDone = styled(Text)`
    color: #616161;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
`;

const LeftHeaderDone = styled(Flex)`
    color: white;
    gap: 12px;
`;

const RightHeader = styled(Flex)`
    gap: 14px;
`;

const Title = styled(Text)`
    color: #000;
    text-align: center;
    width: 250px;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
`;

const DescriptionText = styled(Text)`
    color: #242424;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 250px;
`;
