import {
    Button,
    Flex,
    Skeleton,
    Text,
    Tooltip,
} from "@fluentui/react-northstar";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";

const Card = ({
    action,
    actionText,
    icon,
    ButtonIcon,
    isPrimary,
    isLoading,
    isFetching,
    due_date,
    status,
}) => {
    const isDuedateAfterToday = moment().isAfter(moment(due_date), "day");

    return (
        <CardStyle space="between" vAlign="center">
            <Flex fill space="between" vAlign="center">
                <Flex styles={{ width: "24px", marginRight: "8px" }}>
                    {isLoading || isFetching ? (
                        <Skeleton>
                            <Skeleton.Text
                                size="medium"
                                style={{ width: "20px" }}
                            />
                        </Skeleton>
                    ) : !status ? (
                        isDuedateAfterToday && (
                            <Tooltip
                                subtle={false}
                                pointing
                                position="after"
                                align="start"
                                content={`Date d'échéance : ${due_date}`}
                                trigger={icon}
                            />
                        )
                    ) : (
                        icon
                    )}
                </Flex>
                <Flex fill vAlign="center" style={{ gap: "24px" }}>
                    {isLoading || isFetching ? (
                        <Skeleton>
                            <Skeleton.Text
                                size="large"
                                styles={{ width: "120px" }}
                            />
                        </Skeleton>
                    ) : (
                        <CardText truncated content={actionText} />
                    )}
                </Flex>

                {isLoading || isFetching ? (
                    <Skeleton>
                        <Skeleton.Button
                            siz="small"
                            animation={"wave"}
                            styles={{ borderRadius: "4px" }}
                        />
                    </Skeleton>
                ) : action ? (
                    <ButtonStyle
                        tinted
                        icon={ButtonIcon}
                        content={action}
                        flat
                        primary={isPrimary}
                        size="small"
                    />
                ) : null}
            </Flex>
        </CardStyle>
    );
};

export default Card;

const ButtonStyle = styled(Button)`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    min-width: 110px;
    padding-inline: 8px;
    border: 0;
    background-color: #f5f5f5;
    color: #242424;
`;

const CardText = styled(Text)`
    overflow: hidden;

    color: #242424;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px;
    white-space: nowrap;

    text-overflow: ellipsis;

    @media ${DEVICE.mobileS} {
        width: 100px;
    }
    @media ${DEVICE.mobileM} {
        width: 160px;
    }
    @media ${DEVICE.tablet} {
        width: clamp(100px, 100%, 300px);
    }
`;

const CardStyle = styled(Flex)`
    border: 1px solid #d1d1d1;
    min-height: 48px;
    background-color: white;
    border-radius: 8px;

    @media ${DEVICE.mobileS} {
        /* width: calc(100vw - 32px); */
        padding-inline: 8px;
        width: 280px;
    }
    @media ${DEVICE.mobileM} {
        /* width: calc(100vw - 32px); */
        padding-inline: 8px;
        width: 340px;
    }
    @media ${DEVICE.mobileL} {
        /* width: calc(100vw - 32px); */
        padding-inline: 8px;
        width: 370px;
    }
    @media ${DEVICE.tablet} {
        /* width: calc(100vw - 32px); */
        width: 460px;
        padding-inline: 8px;
    }
    @media ${DEVICE.laptop} {
        /* width: 444px; */
        padding-inline: 16px;
    }
`;
