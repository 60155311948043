import { Dropdown, Flex, Text } from "@fluentui/react-northstar";
import styled from "styled-components";
import { DEVICE } from "../../../../../constants/responsive";

export const SubTitle = styled(Text)`
    color: #242424;

    font-size: clamp(14px, 4vw, 18px);
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
`;

export const DatesFields = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 8px;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
        gap: 32px;
    }
`;
export const EmployeesDropdown = styled(Flex)``;

export const DropdownEmployees = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        .ui-dropdown__container {
            width: 100%;
        }
    }
    @media ${DEVICE.mobileL} {
        .ui-dropdown__container {
            width: 365px;
        }
    }
`;

export const DropdownTypeSolde = styled(Dropdown)`
    @media ${DEVICE.mobileS} {
        .ui-dropdown__container {
            width: 100%;
        }
    }
    @media ${DEVICE.mobileL} {
        .ui-dropdown__container {
            width: 365px;
        }
    }
`;

export const WrapperCheckboxes = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
    }
    @media ${DEVICE.tablet} {
        flex-direction: row;
    }
`;
