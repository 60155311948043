import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Input,
    InputLabel,
    Text,
} from "@fluentui/react-northstar";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
    Ball,
    ErrorMessage,
    InputStyle,
    MuiPhoneNumberStyle,
    Number,
    TextPrimary,
} from "../styles";
import { I18N_NS_ADD_EMPLOYEE } from "../constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { SCHEMA } from "../constants/validators";
import { useMutation } from "react-query";
import { createUser, modifyCollaborator } from "../../../api/user";
import {
    f_resetStepperContext,
    useSetStepPersonalInformations,
    useStepper,
} from "../stores";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { DialogCancelConfirmation } from "../../../components/Dialogs/DialogCancelConfirmation";
import useToast from "../../../hooks/useToast";
import Toast from "../../../components/Alerts";
import { useAuthDetailStore } from "../../../stores/useAuthStore";
import useMutationDeleteEmployee from "../hooks/useMutationDeleteEmployee";

const StepGeneralInformation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(I18N_NS_ADD_EMPLOYEE);
    const {
        agency: { country_code },
    } = useAuthDetailStore((state) => state.detail);
    const { isLoading: is_mutate_user, mutate: mutate_delete_user } =
        useMutationDeleteEmployee();
    const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
    const [configToast, setConfigToast] = useToast();
    const store_set_step_informations = useSetStepPersonalInformations();
    const context_step_informations = useStepper(
        (state) => state.step_personal_informations
    );

    const {
        handleSubmit,
        register,
        formState: { errors, isValid },
        control,
        setValue,
        trigger,
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(SCHEMA),
        defaultValues: {
            last_name: context_step_informations?.last_name ?? "",
            used_name: context_step_informations?.used_name ?? "",
            email: context_step_informations?.email ?? "",
            name: context_step_informations?.name ?? "",
            telephone: context_step_informations?.telephone ?? "",
        },
    });

    /**
     * query add new employee
     */
    const { mutate: add_new_employee, isLoading } = useMutation(
        "validate_step_one",
        (data) => {
            return createUser(data);
        }
    );
    // update the employee
    const { mutate: update_employee, isLoading: updating_employee } =
        useMutation("revalidate_step_one", (data) => {
            return modifyCollaborator(data);
        });

    const onSubmit = (values) => {
        const { email, last_name, name, telephone, used_name } = values;
        const context_step_personal_informations =
            useStepper.getState().step_personal_informations;
        if (!context_step_personal_informations) {
            add_new_employee(
                {
                    first_name: last_name, // prenom
                    last_name: used_name, // nom usage
                    birth_name: name, // nom
                    email,
                    phone_number: telephone,
                },
                {
                    onSuccess: (data) => {
                        if (data?.success) {
                            store_set_step_informations({
                                email,
                                last_name,
                                name,
                                telephone,
                                used_name,
                                id_user: data?.id,
                            });
                            navigate(`${ROUTES.employee_add}/second`);
                        } else {
                            setConfigToast((state) => {
                                return {
                                    ...state,
                                    visible: true,
                                    level: "danger",
                                    content: data?.message,
                                };
                            });
                        }
                    },
                }
            );
        } else {
            update_employee(
                {
                    first_name: last_name, // prenom
                    last_name: used_name, // nom usage
                    birth_name: name, // nom
                    email,
                    phone_number: telephone,
                },
                {
                    onSuccess: (data) => {
                        if (data?.success) {
                            store_set_step_informations({
                                email,
                                last_name,
                                name,
                                telephone,
                                used_name,
                                id_user: data?.user?.id,
                            });
                            navigate(`${ROUTES.employee_add}/second`);
                        } else {
                            setConfigToast((state) => {
                                return {
                                    ...state,
                                    visible: true,
                                    level: "danger",
                                    content: data?.message,
                                };
                            });
                        }
                    },
                }
            );
        }
        return;
    };

    return (
        <>
            <Toast
                {...configToast}
                onStatusChange={() =>
                    setConfigToast({
                        visible: false,
                        content: "",
                        level: "danger",
                    })
                }
            />
            <Flex vAlign="center" gap="gap.small">
                <Ball
                    active={"true"}
                    already={"true"}
                    vAlign="center"
                    hAlign="center"
                >
                    <Number active={"true"} already={"true"}>
                        1
                    </Number>
                </Ball>
                <TextPrimary>
                    {t("pages.add_employee.step_info.title")}
                </TextPrimary>
                <Text styles={{ color: "red" }}>*</Text>
            </Flex>
            {/* FORMS INFORMATION GENERALE */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Flex gap="gap.medium" styles={{ marginTop: "21px" }} column>
                    <Box>
                        <InputStyle
                            label={t(
                                "pages.add_employee.step_info.form.labels.last_name"
                            )}
                            type="text"
                            inverted
                            fluid
                            name="last_name"
                            {...register("last_name")}
                            defaultValue={
                                context_step_informations?.last_name ?? ""
                            }
                            error={errors?.last_name ? true : false}
                        />
                        {errors?.last_name && (
                            <ErrorMessage
                                content={t(errors.last_name.message)}
                            />
                        )}
                    </Box>
                    <Box>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field: { onChange, onBlur } }) => (
                                <InputStyle
                                    defaultValue={
                                        context_step_informations?.name ?? ""
                                    }
                                    inverted
                                    fluid
                                    type="text"
                                    onChange={onChange}
                                    onBlur={(e) => {
                                        setValue("used_name", e.target.value);
                                        trigger("used_name");
                                        return;
                                    }}
                                    label={t(
                                        "pages.add_employee.step_info.form.labels.name"
                                    )}
                                    error={errors?.name ? true : false}
                                />
                            )}
                        />

                        {errors?.name && (
                            <ErrorMessage content={t(errors.name.message)} />
                        )}
                    </Box>

                    <Box>
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <InputStyle
                                    label={t(
                                        "pages.add_employee.step_info.form.labels.used_name"
                                    )}
                                    type="text"
                                    inverted
                                    fluid
                                    error={errors?.used_name ? true : false}
                                    {...field}
                                />
                            )}
                            name="used_name"
                        />
                        {errors?.used_name && (
                            <ErrorMessage
                                content={t(errors.used_name.message)}
                            />
                        )}
                    </Box>
                    <Box>
                        <InputStyle
                            label={t(
                                "pages.add_employee.step_info.form.labels.email"
                            )}
                            type="email"
                            inverted
                            fluid
                            {...register("email")}
                            name="email"
                            defaultValue={
                                context_step_informations?.email ?? ""
                            }
                            error={errors?.email ? true : false}
                        />
                        {errors?.email && (
                            <ErrorMessage content={t(errors.email.message)} />
                        )}
                    </Box>
                    <Box>
                        <InputLabel
                            content={t(
                                "pages.add_employee.step_info.form.labels.telephone"
                            )}
                        />
                        <Controller
                            control={control}
                            render={({ field: { onChange, onBlur } }) => (
                                <MuiPhoneNumberStyle
                                    value={context_step_informations?.telephone}
                                    defaultCountry={country_code ?? "fr"}
                                    disableAreaCodes={true}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    name="telephone"
                                    error={errors?.telephone ? true : false}
                                />
                            )}
                            name="telephone"
                        />
                        {errors?.telephone && (
                            <ErrorMessage
                                content={t(errors.telephone.message)}
                            />
                        )}
                    </Box>
                    <Flex
                        fill
                        space="between"
                        styles={{
                            marginTop: "160px",
                        }}
                    >
                        <Flex>
                            <DialogCancelConfirmation
                                open={openDialogConfirmation}
                                cancelButton={{
                                    content: "Annuler",
                                    flat: true,
                                }}
                                confirmButton={{
                                    content:
                                        "Annuler l’ajout d’un collaborateur",
                                    flat: true,
                                    isLoading: is_mutate_user,
                                    styles: {
                                        backgroundColor: "var(--color-danger)",
                                    },
                                }}
                                content={
                                    "Vous allez perdre toutes les données saisies sur l’ajout du collaborateur."
                                }
                                trigger={
                                    <Button
                                        content={{
                                            content: "Annuler l'ajout",
                                            styles: {
                                                color: "var(--color-danger)",
                                                textDecoration: "underline",
                                            },
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            setOpenDialogConfirmation(true);
                                        }}
                                        text
                                    />
                                }
                                header="Êtes-vous sûr.e de annuler l’ajout d’un collaborateur ?"
                                onClose={() => setOpenDialogConfirmation(false)}
                                onConfirm={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    // reset the context and other data.
                                    const id_user =
                                        context_step_informations?.id_user;
                                    if (id_user) {
                                        mutate_delete_user(
                                            {
                                                id_user:
                                                    context_step_informations?.id_user,
                                            },
                                            {
                                                onSuccess: (data) => {
                                                    if (data?.success) {
                                                        f_resetStepperContext();
                                                        setOpenDialogConfirmation(
                                                            false
                                                        );
                                                        navigate("/dashboard");
                                                        return;
                                                    } else {
                                                        console.log(
                                                            "Une erreur est survenue !"
                                                        );
                                                    }
                                                },
                                                onError: (err) => {
                                                    return console.log({ err });
                                                },
                                            }
                                        );
                                    } else {
                                        setOpenDialogConfirmation(false);
                                        f_resetStepperContext();
                                        navigate("/dashboard");
                                    }
                                    return;
                                }}
                            />
                        </Flex>
                        <Flex gap="gap.small">
                            <Button
                                content="Revenir à l'étape précédente"
                                secondary
                                flat
                                disabled
                            />
                            <Button
                                content="Passer à l'étape suivante"
                                disabled={!isValid}
                                loading={isLoading}
                                primary
                                flat
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </form>
        </>
    );
};

export default StepGeneralInformation;
