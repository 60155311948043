import {
    Checkbox,
    CloseIcon,
    Dialog,
    Divider,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useState } from "react";
import MonthDetail from "../MonthDetail";
import {
    CardDetailWeek,
    ContractualHours,
    Dates,
    DeclaredMonth,
    Graph,
    Hours,
    Label,
    Left,
    Right,
    Title,
    TotalHours,
    WrapperCards,
} from "./index.style";
import { useTranslation } from "react-i18next";
import {
    BAR_GRAPH_MONTH_SUBMIT,
    I18N_NS_TIME_REPORTING,
} from "../../constants";
import { _api_send_month } from "../../api";
import { useEffect } from "react";
import BadgeWeek from "../BadgeWeek";
import HourCounter from "../MyTeam/HourBadge/HourCounter";
import { CustomBadge } from "../../../../components/Badge";
import moment from "moment";
import ContentDetailMonth from "../ContentDetailMonth/ContentDetailMonth";

const DialogSubmitMonth = ({
    open = false,
    weeklyDetail,
    dataMonthSummary,
    month_name,
    month_id,
    total,
    declared_hours_count,
    expected_hours_count,
    onMonthSent,
    is_sending_month,
    mutate_send_month,
    trigger,
    disabled,
    onClose = () => null,
}) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    const [visible, setVisible] = useState(open);

    useEffect(() => {
        setVisible(open);
    }, [open]);

    return (
        <Dialog
            open={visible}
            confirmButton={{
                content: t("v2.home.buttons.submit_activity_month"),
                flat: true,
                loading: is_sending_month,
            }}
            trigger={
                <div
                    id="submit_month"
                    onClick={() => {
                        if (disabled) return;
                        setVisible(true);
                        return;
                    }}
                >
                    {trigger}
                </div>
            }
            cancelButton={{
                content: t("v2.home.buttons.cancel"),
                flat: true,
            }}
            header={t(
                "v2.home.dialogs.titles.question_send_month_to_validation",
                {
                    month_name,
                }
            )}
            headerAction={{
                icon: <CloseIcon />,
                onClick: () => {
                    // setIsChecked(false);
                    setVisible(false);
                },
            }}
            content={
                <ContentDetailMonth
                    special_days={dataMonthSummary?.special_days}
                    declared_hours_count={
                        dataMonthSummary?.declared_hours_count
                    }
                    difference={dataMonthSummary?.difference}
                    expected_hours_count={
                        dataMonthSummary?.expected_hours_count
                    }
                    weeks={dataMonthSummary?.weeks}
                />
            }
            onCancel={() => {
                onClose();
                setVisible(false);
            }}
            onConfirm={() => {
                mutate_send_month(
                    {
                        month: month_id,
                    },
                    {
                        onSuccess: () => {
                            onMonthSent();
                            setVisible(false);
                        },
                    }
                );
            }}
            styles={{
                width: "764px",
            }}
        />
    );
};

export default DialogSubmitMonth;
