import React from "react";
import { Power20Regular } from "@fluentui/react-icons";
import { logout } from "../../../api/auth";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { DEVICE } from "../../../constants/responsive";
const MobileFooter = ({ hideLogout = false }) => {
    const navigate = useNavigate();
    return (
        <Flex column fill space="between" vAlign="center">
            {/* <Flex gap="gap.small">
                <FooterLogo src={FavIconYeetch} />
                <FooterLogo src={LinkedinLogo} />
            </Flex> */}
            <Flex fill column>
                <PolitiqueYeetchFlex
                    column
                    fill

                    // space="between"
                >
                    <Flex space="between">
                        <LinkStyle to="#">
                            <TextLink
                                href="#"
                                content="Politique de confidentialité"
                            />
                        </LinkStyle>
                        <Text content="© Yeetch 2023" />
                    </Flex>
                    <LinkStyle>
                        <TextLink content="Cookies" />
                    </LinkStyle>
                    <LinkStyle to="#">
                        <TextLink content="Mentions légales" />
                    </LinkStyle>

                    <LinkStyle>
                        <TextLink content="RGPD" />
                    </LinkStyle>
                    <LinkStyle>
                        <TextLink content="Conditions générales d’utilisation" />
                    </LinkStyle>
                </PolitiqueYeetchFlex>
            </Flex>
            <Flex styles={{ paddingTop: "24px" }} fill hAlign="center">
                {!hideLogout && (
                    <Button
                        text
                        content={"Se déconnecter"}
                        icon={<Power20Regular color="var(--color-danger)" />}
                        onClick={async () => {
                            await logout();
                            navigate("/login");
                        }}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export default MobileFooter;

const LinkStyle = styled(Link)`
    text-decoration: none;
`;

const TextLink = styled(Text)`
    color: #0067b8;
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

const PolitiqueYeetchFlex = styled(Flex)`
    width: 100%;
    @media ${DEVICE.mobileS} {
        justify-content: space-between;
        padding-inline: 20px;
        gap: 16px;
    }
    @media ${DEVICE.mobileM} {
    }
    @media ${DEVICE.mobileL} {
    }
`;
