import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QUERY_KEY } from "../../constants";
import {
    _api_delete_task,
    _api_get_detail_one_task,
    _api_validate_document,
    _update_status_task,
} from "../../../../api/tasks";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import ModalDetailTask from "../../ModalDetailTask";
import { Container, ContainerFluid } from "../../../../common/styles";
import {
    IllustrationSubText,
    IllustrationText,
    SubHeader,
    SubHeaderText,
    Title,
} from "../styles";
import { ChevronLeft } from "@mui/icons-material";
import LoaderPage from "../../../../components/loaders/LoaderPage";
import { TASK_STATUS } from "../../../../constants/tasks";
import PdfViewerComponent from "../../../../components/PdfViewerComponent";
import Illustration from "../../../../components/shared/Illustration";

const GeneralDetailTaskContent = () => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const isHrPage = state?.isHrPage ?? false;

    const { id_task, id_user } = useParams();
    const [savedFiles, setSavedFiles] = useState([]);
    const [document, setDocument] = useState(null);

    const { data, isLoading, isFetching } = useQuery(
        [QUERY_KEY + "::get_detail_one_task"],
        () => {
            return _api_get_detail_one_task({
                id_task,
                id_user,
            });
        },
        {
            refetchOnWindowFocus: false,
        }
    );

    // mutations
    // * complete task
    const {
        isLoading: isMutatingCompleteTask,
        mutateAsync: mutateCompleteTask,
    } = useMutation((data) => _api_validate_document(data));

    // * update status task
    const {
        isLoading: isMutatingUpdateStatus,
        mutateAsync: mutateUpdateStatus,
    } = useMutation((data) => _update_status_task(data));

    const { isLoading: is_deleting_task, mutate } = useMutation((data) =>
        _api_delete_task(data)
    );

    useEffect(() => {
        setDocument(data?.task?.documents_template?.at(0)?.attached_doc);
    }, [data?.task?.documents_template?.at(0)?.attached_doc]);

    if (isLoading || isFetching) {
        return <LoaderPage />;
    }

    const goBack = ({ message = null }) => {
        const data = {};
        if (message) data.message = message;
        return navigate(state?.referrer ?? "/dashboard/tasks", { state: data });
    };

    /** show the value of the button According to CASE  */
    const _renderEmployeeValueButton = (task) => {
        // si la tache est "a faire" + document A SIGNE
        if (task.status === TASK_STATUS.TODO && task.document_action === 4) {
            return "Valider la signature du document";
        }
        // si la tache est "a faire" + document A COMPLETE
        if (task.status === TASK_STATUS.TODO && task.document_action === 3) {
            return "Envoyer le document complété";
        }
        // si la tache est "a faire" + document CONSULTE
        if (task.status === TASK_STATUS.TODO && task.document_action === 2) {
            return "Valider la lecture du document";
        }
        // si la tache est "a faire" + document A ENVOYER
        if (task.status === TASK_STATUS.TODO && task.document_action === 1) {
            return "Envoyer le document";
        }
        // si la tache est "a faire" + document AUCUNE ACTION
        if (task.status === TASK_STATUS.TODO && task.document_action === 0) {
            return "Terminer la tâche";
        }
        return false;
    };

    /** execute mutates According to CASES  */
    const executeTaskDependStatus = async (task) => {
        // envoyer les documents vers backend relier avec une tâche.
        if (task.document_required && task.status !== TASK_STATUS.TO_APPROVE) {
            if (!savedFiles.length) {
                alert("Pas de document 😱😱😱");
                return;
            }
            const fetched_complete = await mutateCompleteTask({
                task_id: task.id,
                new_doc_ids: savedFiles.map((el) => el.id),
                assigned_to: task.assigned_to_user.id,
            });
        }

        if (task.status === TASK_STATUS.TODO) {
            const response = await mutateUpdateStatus({
                task_id: task.id,
                task_status: task.task_to_validate
                    ? TASK_STATUS.TO_APPROVE
                    : TASK_STATUS.DONE,
            });
            if (response?.success) {
                return goBack({ message: "Votre action est bien terminée" });
            }
        }

        if (task.status === TASK_STATUS.TO_APPROVE) {
            // si c'est le collaborateur qui est connecte
            // alors change status to TODO directement.
            const response = await mutateUpdateStatus({
                task_id: task.id,
                task_status: TASK_STATUS.DONE,
            });

            if (response?.success) {
                return goBack({ message: "Votre action est bien terminée" });
            }
        }
        console.log("NOTHING");
    };

    const task = data?.task;

    const hideButtons =
        task?.status === TASK_STATUS.DONE ||
        task?.status === TASK_STATUS.TO_APPROVE ||
        isHrPage;

    const illustration_message =
        task?.document_action === 0 || isHrPage
            ? "Aucun document à visualiser"
            : "";

    return (
        <ContainerFluid column>
            <Container column>
                {/* header */}
                <SubHeader vAlign="center" gap="gap.small">
                    <Button
                        iconOnly={true}
                        icon={<ChevronLeft />}
                        flat
                        onClick={goBack}
                    />
                    <SubHeaderText content={"Détail d'une tâche "} />
                </SubHeader>
                {/* BODY */}
                <Flex fill styles={{ backgroundColor: "#F5F5F5" }}>
                    {/* left */}
                    <Flex
                        styles={{
                            padding: "24px",
                            maxWidth: "430px",
                            height: "fit-content",
                            backgroundColor: "white",
                            background: "#FFF",
                            boxShadow:
                                "0px 0.15px 0.45px 0px rgba(0, 0, 0, 0.11), 0px 0.8px 1.8px 0px rgba(0, 0, 0, 0.13)",
                        }}
                        column
                        fill
                        gap="gap.large"
                    >
                        <Flex column gap="gap.medium">
                            <Title>{data?.task?.name}</Title>
                            <ModalDetailTask
                                data={{ ...task }}
                                savedFiles={savedFiles}
                                setSavedFiles={setSavedFiles}
                                hideCreationAndValidation={false}
                                isPreviewDocument={true}
                                documentToPreview={document}
                                isHrPage={isHrPage}
                                onClickToPreview={(new_document_url) => {
                                    return setDocument(new_document_url);
                                }}
                            />
                        </Flex>
                        {/* footer => button actions */}
                        <Flex hAlign="center">
                            {!hideButtons && (
                                <Button
                                    content={_renderEmployeeValueButton(task)}
                                    onClick={() => {
                                        return executeTaskDependStatus(task);
                                    }}
                                    loading={
                                        isMutatingCompleteTask ||
                                        isMutatingUpdateStatus
                                    }
                                    disabled={
                                        savedFiles?.length === 0 &&
                                        task.document_required &&
                                        task.status !== TASK_STATUS.TO_APPROVE
                                    }
                                    primary
                                    flat
                                />
                            )}
                            {isHrPage && (
                                <Button
                                    content={"Supprimer la tâche"}
                                    fluid
                                    flat
                                    loading={is_deleting_task}
                                    onClick={() => {
                                        mutate(
                                            {
                                                id_user:
                                                    task?.assigned_to_user?.id,
                                                task_id: task?.id,
                                            },
                                            {
                                                onSuccess: (data) => {
                                                    if (data?.success) {
                                                        return goBack({
                                                            message:
                                                                "Votre action est bien terminée",
                                                        });
                                                    }
                                                },
                                            }
                                        );
                                        return;
                                    }}
                                    styles={{
                                        color: "var(--color-danger)",
                                    }}
                                />
                            )}
                        </Flex>
                    </Flex>
                    {/* right */}
                    <Flex
                        fill
                        styles={{
                            backgroundColor: "transparent",
                            height: "80vh",
                            width: "100%",
                        }}
                        className="PDF-viewer"
                    >
                        {document && document !== "undefined" && (
                            <PdfViewerComponent document={document} />
                        )}
                        {task?.document_action === 0 && (
                            <Flex
                                column
                                hAlign="center"
                                vAlign="center"
                                gap="gap.small"
                                fill
                            >
                                <Illustration
                                    filename={"tasks/tasks_document.svg"}
                                    width="250px"
                                    height="210px"
                                />
                                <IllustrationText
                                    content={illustration_message}
                                    weight="bold"
                                />
                            </Flex>
                        )}
                        {task?.document_action === 1 &&
                            savedFiles.length === 0 &&
                            task.status !== TASK_STATUS.DONE &&
                            !isHrPage && (
                                <Flex
                                    column
                                    hAlign="center"
                                    vAlign="center"
                                    gap="gap.small"
                                    fill
                                >
                                    <Illustration
                                        filename={"tasks/tasks_document.svg"}
                                        width="250px"
                                        height="210px"
                                    />
                                    <IllustrationText
                                        content={
                                            "Vous n’avez pas importé de document"
                                        }
                                        weight="bold"
                                    />
                                    <IllustrationSubText
                                        content={
                                            "Pour en importer un veuillez utiliser l’élément en violet sur votre gauche"
                                        }
                                    />
                                </Flex>
                            )}
                    </Flex>
                </Flex>
            </Container>
        </ContainerFluid>
    );
};

export default GeneralDetailTaskContent;
